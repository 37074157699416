import styled from "@emotion/styled";
import { Drawer, drawerClasses } from "@mui/material";
import { theme } from "@product/scmp-sdk";

import { ZIndexLayer } from "shared/lib/styles";

export const StyledDrawer = styled(Drawer)`
  z-index: ${ZIndexLayer.Drawer};

  & .${drawerClasses.paper} {
    overflow-y: hidden;
  }

  & .${drawerClasses.modal} {
    background: rgba(0, 0, 0, 0.2);
  }
`;

export const CommentContainer = styled.div`
  position: relative;

  inline-size: 100vw;
  block-size: 100vh;

  background-color: #ffffff;

  ${theme.breakpoints.up("tablet")} {
    inline-size: 485px;
  }
`;

export const StyledIFrame = styled.iframe`
  inline-size: 100%;
  block-size: 100%;

  border: 0;
`;
