import { usePrevious, useSyncedRef } from "@react-hookz/web";
import YouTube from "@vip30/react-youtube";
import type { SpeechifyExperienceEvent } from "https://storage.googleapis.com/speechify-api-cdn/speechifyapi.min.mjs";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";

import { onlyOnePlayingPlayerAtom } from "./atoms";

export const useOnlyOnePlayingPlayer = () => {
  const [{ adsManager, articleSpeechifyPlayer, youtubePlayer }, setOnlyOnePlayingPlayerAtom] =
    useAtom(onlyOnePlayingPlayerAtom);

  const latestAdsManager = useSyncedRef(adsManager);
  const latestArticleSpeechifyPlayer = useSyncedRef(articleSpeechifyPlayer);
  const latestYoutubePlayer = useSyncedRef(youtubePlayer);

  useEffect(() => {
    if (!adsManager) {
      return;
    }

    if (adsManager.getRemainingTime() > 0) {
      latestArticleSpeechifyPlayer.current?.pause();
      void latestYoutubePlayer.current?.pauseVideo();
    }

    const handleAdStarted = () => {
      latestArticleSpeechifyPlayer.current?.pause();
      void latestYoutubePlayer.current?.pauseVideo();
      setOnlyOnePlayingPlayerAtom(state => ({ ...state, stopYoutubeAutoplay: false }));
    };
    adsManager.addEventListener(google.ima.AdEvent.Type.STARTED, handleAdStarted);
    adsManager.addEventListener(google.ima.AdEvent.Type.RESUMED, handleAdStarted);
    return () => {
      adsManager.removeEventListener(google.ima.AdEvent.Type.STARTED, handleAdStarted);
      adsManager.removeEventListener(google.ima.AdEvent.Type.RESUMED, handleAdStarted);
    };
  }, [adsManager, latestArticleSpeechifyPlayer, latestYoutubePlayer, setOnlyOnePlayingPlayerAtom]);

  useEffect(() => {
    if (!articleSpeechifyPlayer) {
      return;
    }

    const handleStopOtherPlayers = () => {
      latestAdsManager.current?.pause();
      void latestYoutubePlayer.current?.pauseVideo();
      setOnlyOnePlayingPlayerAtom(state => ({ ...state, stopYoutubeAutoplay: true }));
    };

    const run = async () => {
      const playerState = await articleSpeechifyPlayer.getPlayerState();
      if (playerState.playing) return handleStopOtherPlayers();
    };
    void run();

    const destructor = articleSpeechifyPlayer.addEventListener(
      (event: SpeechifyExperienceEvent) => {
        switch (event.type) {
          case "pause":
            setOnlyOnePlayingPlayerAtom(state => ({ ...state, stopYoutubeAutoplay: false }));
            break;
          case "play":
          case "replay":
          case "resume":
            handleStopOtherPlayers();
            break;
        }
      },
    );

    return () => destructor();
  }, [articleSpeechifyPlayer, latestAdsManager, latestYoutubePlayer, setOnlyOnePlayingPlayerAtom]);

  useEffect(() => {
    if (!youtubePlayer) {
      return;
    }

    // We don't want to pause the ima player when the youtube is playing because of the
    // possibility of the youtube player is playing in the background which is not expected by us
    const pauseOtherPlayerExceptAds = (playerState: number) => {
      if (
        playerState !== YouTube.PlayerState.PLAYING ||
        (latestAdsManager.current && latestAdsManager.current?.getRemainingTime() > 0)
      )
        return;
      latestArticleSpeechifyPlayer.current?.pause();
    };

    const run = async () => {
      const playerState: number = await youtubePlayer.getPlayerState();
      pauseOtherPlayerExceptAds(playerState);
    };
    void run();

    const handleOnStateChange = (event: CustomEvent) => {
      const { data } = event as unknown as { data: number };
      if (data !== YouTube.PlayerState.PLAYING) return;
      pauseOtherPlayerExceptAds(data);
      setOnlyOnePlayingPlayerAtom(state => ({ ...state, stopYoutubeAutoplay: false }));
    };
    void youtubePlayer.addEventListener("onStateChange", handleOnStateChange);
    return () => {
      void youtubePlayer.removeEventListener("onStateChange", handleOnStateChange);
    };
  }, [latestAdsManager, latestArticleSpeechifyPlayer, setOnlyOnePlayingPlayerAtom, youtubePlayer]);
  useOnlyOneYoutubePlayerEffect();
};

const useOnlyOneYoutubePlayerEffect = () => {
  const { youtubePlayer } = useAtomValue(onlyOnePlayingPlayerAtom);

  const previousYoutubePlayer = usePrevious(youtubePlayer);

  useEffect(() => {
    const run = async () => {
      const previousVideoIframe = await previousYoutubePlayer?.getIframe();
      const currentVideoIframe = await youtubePlayer?.getIframe();
      if (!previousVideoIframe || !currentVideoIframe) return;
      else if (previousVideoIframe !== currentVideoIframe) {
        void previousYoutubePlayer?.pauseVideo();
      }
    };
    void run();
  }, [previousYoutubePlayer, youtubePlayer]);
};
