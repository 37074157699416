import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { checkIsStyleArticle } from "scmp-app/components/article/article-render/style-article/helper";
import type { articleDividerArticle$key } from "scmp-app/queries/__generated__/articleDividerArticle.graphql";

import StyleIcon from "./icon-style.svg";
import { Divider, StyleDivider, StyleIconContainer } from "./styles";

export type Props = {
  className?: string;
  reference: articleDividerArticle$key;
};

export const ArticleDivider: FunctionComponent<Props> = ({ className, reference: reference_ }) => {
  const article = useFragment(
    graphql`
      fragment articleDividerArticle on Article {
        ...helpersCheckIsStyleArticle
      }
    `,
    reference_,
  );

  if (checkIsStyleArticle(article))
    return (
      <StyleDivider className={className}>
        <StyleIconContainer>
          <StyleIcon />
        </StyleIconContainer>
      </StyleDivider>
    );

  return <Divider className={className} />;
};

ArticleDivider.displayName = "ArticleDivider";
