/**
 * @generated SignedSource<<2a9416c23b755b12d5602e46512b96b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleSchemaContent$data = {
  readonly article: {
    readonly authorLocations: ReadonlyArray<string | null | undefined> | null | undefined;
    readonly authors: ReadonlyArray<{
      readonly location: string | null | undefined;
      readonly name: string;
      readonly types: ReadonlyArray<string | null | undefined> | null | undefined;
      readonly urlAlias: string;
    } | null | undefined> | null | undefined;
    readonly body: {
      readonly text: string | null | undefined;
    } | null | undefined;
    readonly contentLock: boolean | null | undefined;
    readonly corrections: ReadonlyArray<{
      readonly correction: {
        readonly text: string | null | undefined;
      } | null | undefined;
      readonly timestamp: number;
    } | null | undefined> | null | undefined;
    readonly createdDate: number | null | undefined;
    readonly headline: string | null | undefined;
    readonly images: ReadonlyArray<{
      readonly height: number | null | undefined;
      readonly isSlideshow: boolean | null | undefined;
      readonly url: string;
      readonly width: number | null | undefined;
    } | null | undefined> | null | undefined;
    readonly publishedDate: number | null | undefined;
    readonly sections: ReadonlyArray<{
      readonly value: ReadonlyArray<{
        readonly name: string;
      } | null | undefined> | null | undefined;
    } | null | undefined> | null | undefined;
    readonly socialHeadline: string | null | undefined;
    readonly sponsorType: string | null | undefined;
    readonly subHeadline: {
      readonly text: string | null | undefined;
    } | null | undefined;
    readonly summary: {
      readonly text: string | null | undefined;
    } | null | undefined;
    readonly types: ReadonlyArray<{
      readonly value: ReadonlyArray<{
        readonly name: string;
      } | null | undefined> | null | undefined;
    } | null | undefined> | null | undefined;
    readonly updatedDate: number | null | undefined;
    readonly urlAlias: string;
    readonly " $fragmentSpreads": FragmentRefs<"hooksContentHeadlineTagContent">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"liveBlogPostingLiveArticles">;
  readonly " $fragmentType": "articleSchemaContent";
};
export type articleSchemaContent$key = {
  readonly " $data"?: articleSchemaContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleSchemaContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "entityId",
  "variableName": "entityId"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v4 = [
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flag",
  "storageKey": null
},
v6 = [
  (v1/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "SectionSegment",
  "kind": "LinkedField",
  "name": "sections",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "value",
      "plural": true,
      "selections": (v6/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "types",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlAlias",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": "PARAGRAPH",
      "kind": "LocalArgument",
      "name": "bodyType"
    },
    {
      "kind": "RootArgument",
      "name": "customContents"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "entityId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "entityUuid"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleSchemaContent",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            (v0/*: any*/)
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "article",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "hooksContentHeadlineTagContent",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ArticleTypeSegment",
                      "kind": "LinkedField",
                      "name": "types",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ArticleType",
                          "kind": "LinkedField",
                          "name": "value",
                          "plural": true,
                          "selections": [
                            (v1/*: any*/),
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "FormattedValue",
                              "kind": "LinkedField",
                              "name": "description",
                              "plural": false,
                              "selections": (v3/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "helpersCheckIsStyleArticle",
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "SectionSegment",
                              "kind": "LinkedField",
                              "name": "sections",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Section",
                                  "kind": "LinkedField",
                                  "name": "value",
                                  "plural": true,
                                  "selections": (v4/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "Content",
                          "abstractKey": "__isContent"
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    }
                  ],
                  "type": "Article",
                  "abstractKey": null
                },
                (v5/*: any*/),
                (v7/*: any*/),
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "hooksContentHelperContent",
                  "selections": [
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "helpersSanitizeArticleTypeEntityIdsArticle",
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ArticleTypeSegment",
                              "kind": "LinkedField",
                              "name": "types",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "ArticleType",
                                  "kind": "LinkedField",
                                  "name": "value",
                                  "plural": true,
                                  "selections": (v4/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "Article",
                          "abstractKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "helpersSanitizeAuthorTypesArticle",
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Author",
                              "kind": "LinkedField",
                              "name": "authors",
                              "plural": true,
                              "selections": [
                                (v8/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "Article",
                          "abstractKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "helpersSanitizedAuthorEntityIdsArticle",
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Author",
                              "kind": "LinkedField",
                              "name": "authors",
                              "plural": true,
                              "selections": (v4/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "type": "Article",
                          "abstractKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    },
                    (v5/*: any*/)
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "type": "Content",
              "abstractKey": "__isContent"
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "authorLocations",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentLock",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "headline",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publishedDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "socialHeadline",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sponsorType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedDate",
          "storageKey": null
        },
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Author",
          "kind": "LinkedField",
          "name": "authors",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "location",
              "storageKey": null
            },
            (v1/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "customContents",
              "variableName": "customContents"
            },
            {
              "kind": "Variable",
              "name": "type",
              "variableName": "bodyType"
            }
          ],
          "concreteType": "FormattedValue",
          "kind": "LinkedField",
          "name": "body",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FormattedValue",
          "kind": "LinkedField",
          "name": "summary",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FormattedValue",
          "kind": "LinkedField",
          "name": "subHeadline",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Correction",
          "kind": "LinkedField",
          "name": "corrections",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FormattedValue",
              "kind": "LinkedField",
              "name": "correction",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timestamp",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "images",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isSlideshow",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "height",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "width",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ArticleTypeSegment",
          "kind": "LinkedField",
          "name": "types",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ArticleType",
              "kind": "LinkedField",
              "name": "value",
              "plural": true,
              "selections": (v6/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "entityUuid",
          "variableName": "entityUuid"
        }
      ],
      "kind": "FragmentSpread",
      "name": "liveBlogPostingLiveArticles"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "f311c7a89139c512c6cf1a83ffb45a58";

export default node;
