import {
  article as articleData,
  section as sectionData,
  starlingArticleEntityIds,
} from "shared/data";

export const ExcludedArticleEntityIdsInAIEngine = [...starlingArticleEntityIds];

export const ExcludedArticleTypesInAIEngine = [
  {
    entityId: articleData.types.infographicLongRead.entityId,
    name: articleData.types.infographicLongRead.name,
  },
  { entityId: articleData.types.live.entityId, name: articleData.types.live.name },
  { entityId: articleData.types.series.entityId, name: articleData.types.series.name },
];

export const MagazinesStyleSectionsInAIEngine = [
  [sectionData.magazine.name, sectionData.style.name].join("|"),
];

export const BaseExcludedSectionsInAIEngine = [sectionData.posties.name, sectionData.yp.name];
