/**
 * @generated SignedSource<<7a304d6afdec1d87810a406a8345ec7f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleRecommendationArticle$data = {
  readonly entityId: string;
  readonly moreOnThisArticles: ReadonlyArray<{
    readonly entityId: string;
    readonly paywallTypes: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
    readonly publishedDate: number | null | undefined;
    readonly sections: ReadonlyArray<{
      readonly value: ReadonlyArray<{
        readonly entityId: string;
      } | null | undefined> | null | undefined;
    } | null | undefined> | null | undefined;
    readonly types: ReadonlyArray<{
      readonly value: ReadonlyArray<{
        readonly entityId: string;
      } | null | undefined> | null | undefined;
    } | null | undefined> | null | undefined;
    readonly urlAlias: string;
    readonly urlRedirect: {
      readonly toUrl: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsStyleArticle">;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsStyleArticle">;
  readonly " $fragmentType": "articleRecommendationArticle";
};
export type articleRecommendationArticle$key = {
  readonly " $data"?: articleRecommendationArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleRecommendationArticle">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "SectionSegment",
  "kind": "LinkedField",
  "name": "sections",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "value",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "kind": "InlineDataFragmentSpread",
  "name": "helpersCheckIsStyleArticle",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v2/*: any*/)
      ],
      "type": "Content",
      "abstractKey": "__isContent"
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleRecommendationArticle",
  "selections": [
    (v3/*: any*/),
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "moreOnThisArticles",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "PaywallType",
          "kind": "LinkedField",
          "name": "paywallTypes",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publishedDate",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ArticleTypeSegment",
          "kind": "LinkedField",
          "name": "types",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ArticleType",
              "kind": "LinkedField",
              "name": "value",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlAlias",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UrlRedirect",
          "kind": "LinkedField",
          "name": "urlRedirect",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "toUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "21aff7d46277c03e3930132e89aa838e";

export default node;
