import dynamic from "next/dynamic";

import type { Props as GenericArticleFooterProps } from "./generic-article-footer";
import type { Props as MorningStudioArticleFooterProps } from "./morning-studio-article-footer";
import type { Props as PostiesArticleFooterProps } from "./posties-article-footer";

export const DynamicPostiesArticleFooter = dynamic<PostiesArticleFooterProps>(() =>
  import("./posties-article-footer").then(module => module.PostiesArticleFooter),
);

export const DynamicGenericArticleFooter = dynamic<GenericArticleFooterProps>(() =>
  import("./generic-article-footer").then(module => module.GenericArticleFooter),
);

export const DynamicMorningStudioArticleFooter = dynamic<MorningStudioArticleFooterProps>(() =>
  import("./morning-studio-article-footer").then(module => module.MorningStudioArticleFooter),
);
