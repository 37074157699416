import { useMountEffect, useUnmountEffect } from "@react-hookz/web";
import type { FunctionComponent } from "react";

import { useArticleSpeechPlayerController } from "./hooks";

export const ArticleSpeechPlayer: FunctionComponent = () => {
  const { handleCurrentPlayerUnmountAndCleanUp, handleInitSpeechifyModule } =
    useArticleSpeechPlayerController();

  useMountEffect(() => {
    handleInitSpeechifyModule();
  });

  useUnmountEffect(() => {
    void handleCurrentPlayerUnmountAndCleanUp();
  });

  return null;
};

ArticleSpeechPlayer.displayName = "ArticleSpeechPlayer";
