/**
 * @generated SignedSource<<1fd4c2f816160f76402dd089b2b122c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleRecommendationQuery$variables = {
  entityIds?: ReadonlyArray<string | null | undefined> | null | undefined;
  first: number;
  scmpPlusPaywallTypeIds?: ReadonlyArray<string | null | undefined> | null | undefined;
};
export type articleRecommendationQuery$data = {
  readonly articles: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly entityId: string;
        readonly paywallTypes: ReadonlyArray<{
          readonly entityId: string;
        } | null | undefined> | null | undefined;
        readonly sections: ReadonlyArray<{
          readonly value: ReadonlyArray<{
            readonly entityId: string;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        readonly types: ReadonlyArray<{
          readonly value: ReadonlyArray<{
            readonly entityId: string;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        readonly urlRedirect: {
          readonly toUrl: string;
        } | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsStyleArticle">;
      };
    }>;
  };
};
export type articleRecommendationQuery = {
  response: articleRecommendationQuery$data;
  variables: articleRecommendationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entityIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scmpPlusPaywallTypeIds"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "paywallTypeIds",
        "variableName": "scmpPlusPaywallTypeIds"
      }
    ],
    "kind": "ObjectValue",
    "name": "exclude"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "entityIds",
        "variableName": "entityIds"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "SectionSegment",
  "kind": "LinkedField",
  "name": "sections",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "value",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "toUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "articleRecommendationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ArticleConnection",
        "kind": "LinkedField",
        "name": "articles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArticleEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Article",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "helpersCheckIsStyleArticle",
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v4/*: any*/)
                        ],
                        "type": "Content",
                        "abstractKey": "__isContent"
                      }
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PaywallType",
                    "kind": "LinkedField",
                    "name": "paywallTypes",
                    "plural": true,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ArticleTypeSegment",
                    "kind": "LinkedField",
                    "name": "types",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ArticleType",
                        "kind": "LinkedField",
                        "name": "value",
                        "plural": true,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UrlRedirect",
                    "kind": "LinkedField",
                    "name": "urlRedirect",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "articleRecommendationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ArticleConnection",
        "kind": "LinkedField",
        "name": "articles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArticleEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Article",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PaywallType",
                    "kind": "LinkedField",
                    "name": "paywallTypes",
                    "plural": true,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SectionSegment",
                    "kind": "LinkedField",
                    "name": "sections",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Section",
                        "kind": "LinkedField",
                        "name": "value",
                        "plural": true,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ArticleTypeSegment",
                    "kind": "LinkedField",
                    "name": "types",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ArticleType",
                        "kind": "LinkedField",
                        "name": "value",
                        "plural": true,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UrlRedirect",
                    "kind": "LinkedField",
                    "name": "urlRedirect",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isContent"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6ab15d42f16d6c3e68e66a532d185d08",
    "id": null,
    "metadata": {},
    "name": "articleRecommendationQuery",
    "operationKind": "query",
    "text": "query articleRecommendationQuery(\n  $entityIds: [String]\n  $first: Int!\n  $scmpPlusPaywallTypeIds: [String]\n) {\n  articles(exclude: {paywallTypeIds: $scmpPlusPaywallTypeIds}, filter: {entityIds: $entityIds}, first: $first) {\n    edges {\n      node {\n        ...helpersCheckIsStyleArticle\n        entityId\n        paywallTypes {\n          entityId\n          id\n        }\n        sections {\n          value {\n            entityId\n            id\n          }\n        }\n        types {\n          value {\n            entityId\n            id\n          }\n        }\n        urlRedirect {\n          toUrl\n          id\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment helpersCheckIsStyleArticle on Content {\n  __isContent: __typename\n  sections {\n    value {\n      entityId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "46b9e679af436522e321f9d1df244afe";

export default node;
