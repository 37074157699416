import type { FunctionComponent } from "react";
import { JsonLd } from "react-schemaorg";
import type { AnalysisNewsArticle, NewsArticle } from "schema-dts";

import { useArticleSchemaContext } from "scmp-app/components/article/article-schema/contexts";
import type { SwGCompatibleSchema } from "scmp-app/components/article/article-schema/types";

type Props = Record<string, never>;

export const AnalysisNewsArticleSchema: FunctionComponent<Props> = () => {
  const { schemaData } = useArticleSchemaContext();

  return schemaData ? (
    <JsonLd<SwGCompatibleSchema<NewsArticle, AnalysisNewsArticle>>
      item={{
        "@context": "https://schema.org",
        "@type": ["NewsArticle", "AnalysisNewsArticle"],
        ...schemaData,
      }}
    />
  ) : null;
};

AnalysisNewsArticleSchema.displayName = "AnalysisNewsArticleSchema";
