/**
 * @generated SignedSource<<0ada0f54f57e940424e44ad5daa92711>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleListArticle$data = {
  readonly entityId: string;
  readonly " $fragmentSpreads": FragmentRefs<"articleDividerArticle" | "articleFooterArticle" | "articlePaginationCheckerArticle" | "articlePaginationNavigationHistoryArticle" | "articlePianoPaywallContent" | "articleRecommendationArticle" | "articleRenderArticle" | "contextsArticleListContextProviderArticle" | "contextsContentReadMoreContextProviderContent" | "helpersCheckIsPostiesArticle" | "helpersCheckIsStyleArticle">;
  readonly " $fragmentType": "articleListArticle";
};
export type articleListArticle$key = {
  readonly " $data"?: articleListArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleListArticle">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v1 = [
  {
    "kind": "Variable",
    "name": "bodyType",
    "variableName": "bodyType"
  },
  {
    "kind": "Variable",
    "name": "customContents",
    "variableName": "customContents"
  }
],
v2 = [
  (v0/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SectionSegment",
    "kind": "LinkedField",
    "name": "sections",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Section",
        "kind": "LinkedField",
        "name": "value",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": "PARAGRAPH",
      "kind": "LocalArgument",
      "name": "bodyType"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "customContents"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleListArticle",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleDividerArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleFooterArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articlePaginationCheckerArticle"
    },
    {
      "args": (v1/*: any*/),
      "kind": "FragmentSpread",
      "name": "articlePaginationNavigationHistoryArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleRecommendationArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articlePianoPaywallContent"
    },
    {
      "args": (v1/*: any*/),
      "kind": "FragmentSpread",
      "name": "articleRenderArticle"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckIsPostiesArticle",
      "selections": (v3/*: any*/),
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckIsStyleArticle",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "Content",
          "abstractKey": "__isContent"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contextsContentReadMoreContextProviderContent"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "contextsArticleListContextProviderArticle",
      "selections": (v2/*: any*/),
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "c68666edbe79774c6c5ca1e56b8eef97";

export default node;
