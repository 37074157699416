/**
 * @generated SignedSource<<79c75b5dc16de5777e21b0732eeb0040>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articlePaginationNavigationHistoryArticle$data = {
  readonly entityId: string;
  readonly urlAlias: string;
  readonly " $fragmentSpreads": FragmentRefs<"hooksTrackCurrentItemBase">;
  readonly " $fragmentType": "articlePaginationNavigationHistoryArticle";
};
export type articlePaginationNavigationHistoryArticle$key = {
  readonly " $data"?: articlePaginationNavigationHistoryArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"articlePaginationNavigationHistoryArticle">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "defaultValue": "PARAGRAPH",
    "kind": "LocalArgument",
    "name": "bodyType"
  },
  {
    "defaultValue": ([]/*: any*/),
    "kind": "LocalArgument",
    "name": "customContents"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlAlias",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "bodyType",
    "variableName": "bodyType"
  },
  {
    "kind": "Variable",
    "name": "customContents",
    "variableName": "customContents"
  }
],
v5 = [
  (v1/*: any*/)
],
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SectionSegment",
    "kind": "LinkedField",
    "name": "sections",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Section",
        "kind": "LinkedField",
        "name": "value",
        "plural": true,
        "selections": (v5/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  (v7/*: any*/)
],
v9 = [
  {
    "kind": "InlineDataFragmentSpread",
    "name": "hooksUseNotificationsSection",
    "selections": [
      {
        "kind": "InlineDataFragmentSpread",
        "name": "notificationSection",
        "selections": (v8/*: any*/),
        "args": null,
        "argumentDefinitions": ([]/*: any*/)
      },
      (v1/*: any*/)
    ],
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  }
],
v10 = [
  (v1/*: any*/),
  (v7/*: any*/)
];
return {
  "argumentDefinitions": (v0/*: any*/),
  "kind": "Fragment",
  "metadata": null,
  "name": "articlePaginationNavigationHistoryArticle",
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "hooksTrackCurrentItemBase",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v3/*: any*/),
            {
              "kind": "InlineDataFragmentSpread",
              "name": "atomsCurrentArticleAtomBase",
              "selections": [
                (v3/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "args": (v4/*: any*/),
                      "kind": "FragmentSpread",
                      "name": "articleSeoArticle"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "contentShareWidgetContent"
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "headerContentBookmarkArticle",
                      "selections": (v5/*: any*/),
                      "args": null,
                      "argumentDefinitions": []
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "headerContentCommentTrigger",
                      "selections": [
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "articleCommentsTriggerArticle"
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "helpersCheckIsStyleArticle",
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": (v6/*: any*/),
                          "type": "Content",
                          "abstractKey": "__isContent"
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "helpersUseSharingUrl",
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v2/*: any*/)
                          ],
                          "type": "Content",
                          "abstractKey": "__isContent"
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "articleShareWidgetArticle"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "hooksTrackPageViewArticle"
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "hooksContentInterestArticle",
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "sentiment",
                          "storageKey": null
                        },
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ArticleTypeSegment",
                          "kind": "LinkedField",
                          "name": "types",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ArticleType",
                              "kind": "LinkedField",
                              "name": "value",
                              "plural": true,
                              "selections": (v8/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Topic",
                          "kind": "LinkedField",
                          "name": "topics",
                          "plural": true,
                          "selections": (v8/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "hooksUseCurrentArticleIdsArticle",
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "entityUuid",
                          "storageKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "hooksUseNotificationReadStatusArticle",
                      "selections": (v5/*: any*/),
                      "args": null,
                      "argumentDefinitions": []
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "onboardingNewsletterSubscriptionArticle",
                      "selections": (v6/*: any*/),
                      "args": null,
                      "argumentDefinitions": []
                    }
                  ],
                  "type": "Article",
                  "abstractKey": null
                }
              ],
              "args": (v4/*: any*/),
              "argumentDefinitions": (v0/*: any*/)
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "atomsCurrentNotificationSectionAtomBase",
              "selections": [
                (v3/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Section",
                      "kind": "LinkedField",
                      "name": "fullSectionPath",
                      "plural": true,
                      "selections": (v9/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "Section",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SectionSegment",
                      "kind": "LinkedField",
                      "name": "sections",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Section",
                          "kind": "LinkedField",
                          "name": "value",
                          "plural": true,
                          "selections": (v9/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "Article",
                  "abstractKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "atomsCurrentTakeoverAdSectionsAtomBase",
              "selections": [
                (v3/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": (v6/*: any*/),
                  "type": "Article",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Section",
                      "kind": "LinkedField",
                      "name": "fullSectionPath",
                      "plural": true,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "Section",
                  "abstractKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "atomsCurrentStyleCardCarouselWidgetSectionsAtomBase",
              "selections": [
                (v3/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SectionSegment",
                      "kind": "LinkedField",
                      "name": "sections",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Section",
                          "kind": "LinkedField",
                          "name": "value",
                          "plural": true,
                          "selections": (v10/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "Article",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Section",
                      "kind": "LinkedField",
                      "name": "fullSectionPath",
                      "plural": true,
                      "selections": (v10/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "Section",
                  "abstractKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "type": "BaseWithApplicationAndUrlAlias",
          "abstractKey": "__isBaseWithApplicationAndUrlAlias"
        }
      ],
      "args": (v4/*: any*/),
      "argumentDefinitions": (v0/*: any*/)
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "c661cab2643b8858db571c34a3915949";

export default node;
