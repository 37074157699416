import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import { DynamicGenericArticleFooter, DynamicMorningStudioArticleFooter } from "./dynamics";

const CommonArticleFooterStyle = () => css`
  max-inline-size: 1208px;
  margin-inline: auto;

  ${theme.breakpoints.up("tablet")} {
    margin-block-end: 80px;
  }
`;

export const StyledGenericArticleFooter = styled(DynamicGenericArticleFooter)`
  ${CommonArticleFooterStyle}

  ${theme.breakpoints.only("mobile")} {
    margin-block-start: 38px;
  }
`;

export const StyledMorningStudioArticleFooter = styled(DynamicMorningStudioArticleFooter)`
  ${CommonArticleFooterStyle}
`;
