import { notEmpty } from "@product/scmp-sdk";
import CryptoJS from "crypto-js";
import isString from "lodash/isString";
import type { NextPageContext } from "next";

export const decryptedShare = (context: NextPageContext) => {
  if (
    notEmpty(context.query.share) &&
    isString(context.query.share) &&
    isString(context.query.entityUuid) &&
    isString(context.query.entityId)
  ) {
    try {
      const decryptedData = { entityId: "", fcmId: "", shareTime: 0, userId: "" };
      const secretKey = CryptoJS.enc.Utf8.parse(context.query.entityUuid.replaceAll("-", ""));

      const decodeShare = decodeURI(context.query.share);
      const bytes = CryptoJS.AES.decrypt(decodeShare, secretKey, {
        iv: CryptoJS.enc.Utf8.parse(""),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }).toString(CryptoJS.enc.Utf8);
      const data = JSON.parse(bytes) as { fcmId: string; shareTime: string; userId: string };

      decryptedData.shareTime = Number.parseInt(data.shareTime) * 1000;
      decryptedData.entityId = context.query.entityId;
      decryptedData.userId = data.userId;
      decryptedData.fcmId = data.fcmId;

      return decryptedData;
    } catch (error) {
      console.error("decryptedShare error:", error);
      return;
    }
  }
  return;
};
