import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import {
  setFullContentWidthStyles,
  setFullViewportWidthWithMaxPageWidthStyles,
} from "shared/lib/styles";

export const StyleIconContainer = styled.div`
  padding-inline: 16px;

  background-color: #ffffff;

  ${theme.breakpoints.up("tablet")} {
    padding-inline: 18px;
  }

  svg {
    inline-size: 44px;
    block-size: 27.7px;

    ${theme.breakpoints.up("tablet")} {
      inline-size: 50px;
      block-size: 31.5px;
    }
  }
`;

export const Divider = styled.div`
  position: relative;
  z-index: -1;

  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;

  block-size: 1px;

  background-color: #dddddd;

  &::before,
  &::after {
    content: "";

    display: block;
    flex: none;

    inline-size: 30px;
    block-size: 5px;

    outline: #ffffff solid 4px;
  }

  &::before {
    background-color: #ffca05;
  }

  &::after {
    background-color: #001246;
  }

  &:last-child {
    display: none;
  }

  ${theme.breakpoints.only("mobile")} {
    ${setFullViewportWidthWithMaxPageWidthStyles()}
  }
`;

export const StyleDivider = styled.div`
  position: relative;
  z-index: -1;

  display: flex;
  justify-content: center;
  align-items: center;

  block-size: 1px;

  background-color: #222222;

  &:last-child {
    display: none;
  }
  ${theme.breakpoints.only("mobile")} {
    ${setFullViewportWidthWithMaxPageWidthStyles()}
  }

  ${theme.breakpoints.up("tablet")} {
    ${setFullContentWidthStyles()}
  }
`;
