import type { FunctionComponent, ReactNode } from "react";
import { graphql, useFragment } from "react-relay";

import { section as sectionData } from "shared/data";
import { article as articleData } from "shared/data/article";

import {
  checkArticleIsInSections,
  getArticleType,
} from "scmp-app/components/article/article-render/helpers";
import { checkIsPostiesArticle } from "scmp-app/components/article/article-render/posties/helpers";
import { checkIsStyleArticle } from "scmp-app/components/article/article-render/style-article/helper";
import type { articleFooterArticle$key } from "scmp-app/queries/__generated__/articleFooterArticle.graphql";

import { DynamicPostiesArticleFooter } from "./dynamics";
import { StyledGenericArticleFooter, StyledMorningStudioArticleFooter } from "./styles";

type Props = {
  articleRecommendation?: {
    allArticleIds: string[];
    articleIds: string[];
    recommendationId: string;
  };
  className?: string;
  divider?: ReactNode;
  reference: articleFooterArticle$key;
};
export const ArticleFooter: FunctionComponent<Props> = ({
  articleRecommendation,
  className,
  divider,
  reference: reference_,
}) => {
  const article = useFragment(
    graphql`
      fragment articleFooterArticle on Article {
        ...articleMoreStoriesArticle
        ...helpersCheckIsPostiesArticle
        ...helpersCheckIsStyleArticle
        ...helpersGetArticleTypeArticle
        ...morningStudioArticleFooterContent
        ...helpersCheckArticleIsInSectionsArticle
        ...helpersHasPostiesQuiz
      }
    `,
    reference_,
  );

  const mostPopularQueueName = checkArticleIsInSections(article, [
    sectionData.lifestyle.entityId,
    sectionData.postMagazine.entityId,
    sectionData.peopleCulture.entityId,
  ])
    ? "scmp_trending_section_scmp_trending_section_postmag_last_7_days"
    : undefined;

  if (checkIsPostiesArticle(article)) {
    return <DynamicPostiesArticleFooter className={className} reference={article} />;
  }

  if (checkIsStyleArticle(article)) {
    return null;
  }

  switch (getArticleType(article)) {
    case articleData.types.live.entityId:
      return null;
    case articleData.types.studio.entityId:
      return (
        <>
          {divider}
          <StyledMorningStudioArticleFooter className={className} reference={article} />
        </>
      );
    default:
      return articleRecommendation?.articleIds.length ? (
        <>
          {divider}
          <StyledGenericArticleFooter
            className={className}
            {...articleRecommendation}
            mostPopularQueueName={mostPopularQueueName}
          />
        </>
      ) : null;
  }
};

ArticleFooter.displayName = "ArticleFooter";
