import { useRouter } from "next/router";
import type { FunctionComponent } from "react";
import { useCallback, useEffect, useState } from "react";

import { parseQueryString } from "shared/lib/utils";

import { useLoginDialogStateHelper } from "scmp-app/components/login-dialog/hooks";
import { useCurrentArticleIds } from "scmp-app/lib/current-item";
import { useArticleCommentLink } from "scmp-app/lib/hooks";

import { CloseCommentEvent, LoginSuccessQueryParameters } from "./consts";
import { CommentContainer, StyledDrawer, StyledIFrame } from "./styles";

export const ArticleCommentsDrawer: FunctionComponent = () => {
  const [showComments, setShowComments] = useState(false);
  const { query } = useRouter();
  const [iframeUrl, setIframeUrl] = useState("");
  const { isCommentLink, toggleByUrl: toggleCommentByUrl } = useArticleCommentLink();
  const { openLoginDialog } = useLoginDialogStateHelper();

  useEffect(() => {
    setShowComments(isCommentLink());
  }, [isCommentLink]);

  const { currentArticleId } = useCurrentArticleIds();
  useEffect(() => {
    if (!showComments || !currentArticleId) return;

    const commentUrl = new URL(location.origin + `/scmp_comments/popup/${currentArticleId}`);
    const commentId = parseQueryString(query.commentId);

    if (commentId) {
      commentUrl.searchParams.set("commentID", commentId);
    }

    setIframeUrl(commentUrl.toString());
  }, [showComments, query, currentArticleId]);

  const handleCloseDrawer = useCallback(() => {
    toggleCommentByUrl();
  }, [toggleCommentByUrl]);

  useEffect(() => {
    const handleCloseCommentEvent = (event: Event) => {
      if (event.data.event === CloseCommentEvent) {
        handleCloseDrawer();
      }
    };

    window.addEventListener("message", handleCloseCommentEvent);

    return () => {
      window.removeEventListener("message", handleCloseCommentEvent);
    };
  }, [handleCloseDrawer]);

  useEffect(() => {
    const handler = (event: MessageEvent<{ event: string; url: string }>) => {
      if (event.data.event !== "openLoginLayer") return;
      const accountUrl = new URL(event.data.url);
      accountUrl.searchParams.set(LoginSuccessQueryParameters, "1");
      openLoginDialog({
        description:
          "Login or register to join in the discussion, plus read what others have to say.",
        destination: accountUrl.toString(),
        ga4CustomParameter: {
          trigger_point: "comment",
        },
        title: "Contribute to the conversation today",
      });
    };

    window.addEventListener("message", handler);

    return () => {
      window.removeEventListener("message", handler);
    };
  }, [openLoginDialog]);

  return (
    <StyledDrawer
      anchor="right"
      onClose={handleCloseDrawer}
      open={showComments && iframeUrl !== ""}
    >
      <CommentContainer>
        <StyledIFrame src={iframeUrl} />
      </CommentContainer>
    </StyledDrawer>
  );
};

ArticleCommentsDrawer.displayName = "ArticleCommentsDrawer";

type Event = {
  data: {
    event: string;
  };
};
