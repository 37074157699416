import first from "lodash/first";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { JsonLd } from "react-schemaorg";
import type { BreadcrumbList, ListItem, WebPage } from "schema-dts";

import { getAbsoluteUrl } from "shared/lib/utils";

import type { articleLinkingDataContent$key } from "scmp-app/queries/__generated__/articleLinkingDataContent.graphql";

export type Props = {
  reference: articleLinkingDataContent$key;
};

export const ArticleLinkingData: FunctionComponent<Props> = ({ reference: reference_ }) => {
  const content = useFragment(
    graphql`
      fragment articleLinkingDataContent on Content {
        urlAlias
        sections {
          value {
            ...helpersCheckIfNewsSectionInFirstIndexSection
            ...entityLink
            name
            urlAlias
            urlRedirect {
              toUrl
            }
          }
        }
      }
    `,
    reference_,
  );

  const firstSection = useMemo(() => first(content.sections)?.value ?? [], [content.sections]);

  const sectionSchemaListItem = useMemo<ListItem[]>(() => {
    const schema: ListItem[] = firstSection.map?.((element, index) => ({
      "@type": "ListItem",
      item: {
        "@id": `${getAbsoluteUrl(element?.urlRedirect?.toUrl ?? element?.urlAlias)}`,
        name: element?.name,
      },
      position: index + 1,
    }));
    // Use default list item if schema is empty
    return schema?.length > 0
      ? schema
      : [
          {
            "@type": "ListItem",
            item: {
              "@id": `${getAbsoluteUrl("/news")}`,
              name: "News",
            },
            position: 1,
          },
        ];
  }, [firstSection]);

  return (
    <>
      <JsonLd<BreadcrumbList>
        item={{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: sectionSchemaListItem,
        }}
      />
      <JsonLd<WebPage>
        item={{
          "@context": "https://schema.org",
          "@id": `${getAbsoluteUrl(content.urlAlias)}`,
          "@type": "WebPage",
        }}
      />
    </>
  );
};

ArticleLinkingData.displayName = "ArticleLinkingData";
