import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  fontBarlow,
  fontBarlowSemiCondensed,
  fontRoboto,
  transientOptions,
} from "@product/scmp-sdk";

import { ArticleShareWidget } from "scmp-app/components/article/article-share-widget";
import {
  DrawerCard,
  DrawerCardHeader,
  DrawerCardTitle,
} from "scmp-app/components/common/drawer/styles";
import IconShare from "scmp-app/components/header/header-icon/share.svg";

import { DynamicDrawer } from "./dynamics";

export const StyledArticleShareWidget = styled(ArticleShareWidget)`
  gap: 16px;
`;

export const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  inline-size: 24px;
  block-size: 24px;
`;

export const StyledIconShare = styled(IconShare)``;

export type DrawerProps = {
  $variant?: "default" | "magazines-style";
};
export const StyledDynamicDrawer = styled(DynamicDrawer, { ...transientOptions })<DrawerProps>`
  ${DrawerCard} {
    position: relative;

    inline-size: 100%;
    max-inline-size: 500px;
    padding-block: 20px 32px;
    padding-inline: 20px;
  }

  ${DrawerCardHeader} {
    margin-block-end: 25px;
  }

  ${DrawerCardTitle} {
    inline-size: 100%;

    color: #000000;
    font-weight: 700;
    font-size: 20px;
    font-family: ${fontRoboto};
    font-style: normal;
    line-height: normal;
    text-align: start;

    ${props =>
      props.$variant === "magazines-style" &&
      css`
        color: #222222;
        font-weight: 400;
        font-size: 18px;
        font-family: ${fontBarlow};
        line-height: 21.6px;
        text-transform: uppercase;
      `}
  }

  ${StyledArticleShareWidget} {
    ${props =>
      props.$variant === "magazines-style" &&
      css`
        button {
          > :last-child {
            color: #222222;
            font-weight: 500;
            font-size: 16px;
            font-family: ${fontBarlowSemiCondensed};
            line-height: 16.8px;
            letter-spacing: -0.1px;
          }
        }
      `}
  }
`;
