export const SchemaType: Record<string, string> = {
  AdvertiserContentArticle: "AdvertiserContentArticle",
  AnalysisNewsArticle: "AnalysisNewsArticle",
  BackgroundNewsArticle: "BackgroundNewsArticle",
  LiveBlogPosting: "LiveBlogPosting",
  NullNewsArticle: "NullNewsArticle",
  OpinionNewsArticle: "OpinionNewsArticle",
  ReportageNewsArticle: "ReportageNewsArticle",
  ReviewNewsArticle: "ReviewNewsArticle",
};

export const ArticleTypeToSchemaType: Record<string, string> = {
  Analysis: SchemaType.AnalysisNewsArticle,
  Column: SchemaType.OpinionNewsArticle,
  Explainer: SchemaType.BackgroundNewsArticle,
  Live: SchemaType.LiveBlogPosting,
  Newsletter: SchemaType.NullNewsArticle,
  Opinion: SchemaType.OpinionNewsArticle,
  Review: SchemaType.ReviewNewsArticle,
};

/*  Cspell:ignore ssxxx */
export const ISO8601DateTimeFormat = "yyyy-MM-dd'T'HH:mm:ssxxx";
