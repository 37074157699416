/**
 * @generated SignedSource<<5f964a03d15907169b06354b7964b132>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type liveBlogPostingLiveArticles$data = {
  readonly article: {
    readonly urlAlias: string;
  };
  readonly liveArticle: {
    readonly customTimezone: string | null | undefined;
    readonly liveContents: ReadonlyArray<{
      readonly body: {
        readonly text: string | null | undefined;
      } | null | undefined;
      readonly entityId: string;
      readonly publishedDate: number | null | undefined;
      readonly title: string | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "liveBlogPostingLiveArticles";
};
export type liveBlogPostingLiveArticles$key = {
  readonly " $data"?: liveBlogPostingLiveArticles$data;
  readonly " $fragmentSpreads": FragmentRefs<"liveBlogPostingLiveArticles">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "entityId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "entityUuid"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "liveBlogPostingLiveArticles",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "entityId",
              "variableName": "entityId"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "article",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlAlias",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "entityUuid",
              "variableName": "entityUuid"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "LiveArticle",
      "kind": "LinkedField",
      "name": "liveArticle",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customTimezone",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LiveContent",
          "kind": "LinkedField",
          "name": "liveContents",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "entityId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "publishedDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FormattedValue",
              "kind": "LinkedField",
              "name": "body",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "text",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "9f32ae844773290394e76d4b62611a00";

export default node;
