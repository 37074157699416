import styled from "@emotion/styled";

type ArticleProps = {
  $isShowArticle?: boolean;
};

export const Article = styled.article<ArticleProps>`
  display: ${props => (props.$isShowArticle ? "block" : "none !important")};

  max-inline-size: 1560px;
`;
