/**
 * @generated SignedSource<<fc6d7fd367a301ace193230399f1ede6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleFooterArticle$data = {
  readonly " $fragmentSpreads": FragmentRefs<"articleMoreStoriesArticle" | "helpersCheckArticleIsInSectionsArticle" | "helpersCheckIsPostiesArticle" | "helpersCheckIsStyleArticle" | "helpersGetArticleTypeArticle" | "helpersHasPostiesQuiz" | "morningStudioArticleFooterContent">;
  readonly " $fragmentType": "articleFooterArticle";
};
export type articleFooterArticle$key = {
  readonly " $data"?: articleFooterArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleFooterArticle">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "entityId",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SectionSegment",
    "kind": "LinkedField",
    "name": "sections",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Section",
        "kind": "LinkedField",
        "name": "value",
        "plural": true,
        "selections": (v0/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v2 = [
  {
    "kind": "InlineFragment",
    "selections": (v1/*: any*/),
    "type": "Content",
    "abstractKey": "__isContent"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleFooterArticle",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleMoreStoriesArticle"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckIsPostiesArticle",
      "selections": (v1/*: any*/),
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckIsStyleArticle",
      "selections": (v2/*: any*/),
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersGetArticleTypeArticle",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ArticleTypeSegment",
                  "kind": "LinkedField",
                  "name": "types",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ArticleType",
                      "kind": "LinkedField",
                      "name": "value",
                      "plural": true,
                      "selections": (v0/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "Article",
              "abstractKey": null
            }
          ],
          "type": "Content",
          "abstractKey": "__isContent"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "morningStudioArticleFooterContent"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckArticleIsInSectionsArticle",
      "selections": (v2/*: any*/),
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersHasPostiesQuiz",
      "selections": (v1/*: any*/),
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "19965afd79f22eaf4f4b1658ca114e7f";

export default node;
