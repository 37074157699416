/**
 * @generated SignedSource<<e7095269a42480b8880b5efda0ab2ba3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleDividerArticle$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsStyleArticle">;
  readonly " $fragmentType": "articleDividerArticle";
};
export type articleDividerArticle$key = {
  readonly " $data"?: articleDividerArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleDividerArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleDividerArticle",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckIsStyleArticle",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SectionSegment",
              "kind": "LinkedField",
              "name": "sections",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Section",
                  "kind": "LinkedField",
                  "name": "value",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "entityId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Content",
          "abstractKey": "__isContent"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "837a362ac20e83d708326d12411199bf";

export default node;
