/**
 * @generated SignedSource<<948e98d805af4a3bf45ae89195e677d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articlePaginationCheckerArticle$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsPostiesArticle" | "helpersGetArticleTypeArticle">;
  readonly " $fragmentType": "articlePaginationCheckerArticle";
};
export type articlePaginationCheckerArticle$key = {
  readonly " $data"?: articlePaginationCheckerArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"articlePaginationCheckerArticle">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "entityId",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articlePaginationCheckerArticle",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckIsPostiesArticle",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SectionSegment",
          "kind": "LinkedField",
          "name": "sections",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Section",
              "kind": "LinkedField",
              "name": "value",
              "plural": true,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersGetArticleTypeArticle",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ArticleTypeSegment",
                  "kind": "LinkedField",
                  "name": "types",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ArticleType",
                      "kind": "LinkedField",
                      "name": "value",
                      "plural": true,
                      "selections": (v0/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "Article",
              "abstractKey": null
            }
          ],
          "type": "Content",
          "abstractKey": "__isContent"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "e6f9979ef1905dcd6ff386b26819bd18";

export default node;
