import { notEmpty, removeAtMedia, scmpPlus, theme } from "@product/scmp-sdk";
import { useAsync } from "@react-hookz/web";
import { differenceInDays } from "date-fns";
import { useAtomValue } from "jotai";
import first from "lodash/first";
import { useEffect, useRef } from "react";
import { fetchQuery, graphql, useFragment, useRelayEnvironment } from "react-relay";
import type { Disposable } from "relay-runtime";
import { createOperationDescriptor, getRequest } from "relay-runtime";

import { data, paywall } from "shared/data";
import { acknowledgementGateArticleIds, article as articleData } from "shared/data/article";
import { section as sectionData } from "shared/data/section";
import { accountAtom } from "shared/lib/account";
import { sortArrayByReferenceOrder } from "shared/lib/utils";

import {
  BaseExcludedSectionsInAIEngine,
  ExcludedArticleEntityIdsInAIEngine,
  ExcludedArticleTypesInAIEngine,
  MagazinesStyleSectionsInAIEngine,
} from "scmp-app/components/article/article-list/hooks/const";
import { checkIsStyleArticle } from "scmp-app/components/article/article-render/style-article/helper";
import { fetchAiEngineDataV2 } from "scmp-app/lib/ai-engine/apis";
import { AiEngineDataType } from "scmp-app/lib/ai-engine/enums";
import type { articleRecommendationArticle$key } from "scmp-app/queries/__generated__/articleRecommendationArticle.graphql";
import type { articleRecommendationQuery } from "scmp-app/queries/__generated__/articleRecommendationQuery.graphql";
import type { helpersCheckIsStyleArticle$key } from "scmp-app/queries/__generated__/helpersCheckIsStyleArticle.graphql";

import type { ArticleRecommendation } from "./types";

export const useArticleRecommendation = (article_: articleRecommendationArticle$key) => {
  const article = useFragment(
    graphql`
      fragment articleRecommendationArticle on Article {
        ...helpersCheckIsStyleArticle
        entityId
        moreOnThisArticles {
          ...helpersCheckIsStyleArticle
          entityId
          paywallTypes {
            entityId
          }
          publishedDate
          sections {
            value {
              entityId
            }
          }
          types {
            value {
              entityId
            }
          }
          urlAlias
          urlRedirect {
            toUrl
          }
        }
      }
    `,
    article_,
  );

  const environment = useRelayEnvironment();
  const { user } = useAtomValue(accountAtom);
  const isCurrentMagazinesStyleArticle = checkIsStyleArticle(article);

  const includedSections = isCurrentMagazinesStyleArticle ? MagazinesStyleSectionsInAIEngine : [];
  const excludedSections = [
    ...BaseExcludedSectionsInAIEngine,
    ...(isCurrentMagazinesStyleArticle ? [] : MagazinesStyleSectionsInAIEngine),
  ];

  const disposableReference = useRef<Disposable>();

  const [{ result: articleRecommendation }, { execute: executeFetchRecommendedArticles }] =
    useAsync<ArticleRecommendation>(
      async () => {
        const aiEngineData = await fetchAiEngineDataV2({
          entityId: article.entityId,
          excludedArticleTypes: ExcludedArticleTypesInAIEngine.map(element => element.name),
          excludedSections,
          excludeIds: ExcludedArticleEntityIdsInAIEngine,
          includedSections: includedSections.length > 0 ? includedSections : undefined,
          limit: 20,
          type: AiEngineDataType.MoreOnThisTopic,
          userUuid: user?.uuid,
        });

        const query = graphql`
          query articleRecommendationQuery(
            $entityIds: [String]
            $first: Int!
            $scmpPlusPaywallTypeIds: [String]
          ) {
            articles(
              exclude: { paywallTypeIds: $scmpPlusPaywallTypeIds }
              filter: { entityIds: $entityIds }
              first: $first
            ) {
              edges {
                node {
                  ...helpersCheckIsStyleArticle
                  entityId
                  paywallTypes {
                    entityId
                  }
                  sections {
                    value {
                      entityId
                    }
                  }
                  types {
                    value {
                      entityId
                    }
                  }
                  urlRedirect {
                    toUrl
                  }
                }
              }
            }
          }
        `;
        const variables = {
          entityIds: aiEngineData.data.results.map(result => result.id) ?? [],
          first: aiEngineData.data.results.length ?? 0,
          scmpPlusPaywallTypeIds: [scmpPlus.article.paywallTypeId],
        };

        const queryRequest = getRequest(query);
        const queryDescriptor = createOperationDescriptor(queryRequest, variables);

        const recommendedArticles = await fetchQuery<articleRecommendationQuery>(
          environment,
          query,
          variables,
        ).toPromise();

        type PartialArticle =
          | NonNullable<typeof article.moreOnThisArticles>[0]
          | NonNullable<typeof recommendedArticles>["articles"]["edges"][0]["node"];

        const disposable = environment.retain(queryDescriptor);
        disposableReference.current = disposable;

        const orderedRecommendedArticles = sortArrayByReferenceOrder({
          data: {
            array: recommendedArticles?.articles.edges ?? [],
            idGetter: item => item.node.entityId,
          },
          order: { array: aiEngineData.data.results, idGetter: item => item.id },
        });

        const firstFilteredMoreOnThisArticle = article.moreOnThisArticles?.find(article => {
          if (!notEmpty(article)) return false;

          const isValidUrl = () => !article.urlAlias.match("http")?.length;

          const isExcludedPublishedDate = () => {
            if (!article.publishedDate) return false;
            return differenceInDays(new Date(), new Date(article.publishedDate)) > 30;
          };

          const isExplainerType = () => {
            const mainArticleType = first(article.types);
            if (!mainArticleType?.value) return false;
            return articleData.types.explainer.entityId === first(mainArticleType.value)?.entityId;
          };

          return (
            isValidUrl() &&
            !hasRedirectSetupToExternalDomain(article) &&
            !isAcknowledgementRequiredArticle(article) &&
            !isScmpPlusPaywallArticle(article) &&
            (!isExcludedPublishedDate() || isExplainerType()) &&
            !isExcludedSection(article) &&
            isV2SupportedArticle(article) &&
            passMagazinesStyleArticleTypeChecker(article)
          );
        });

        const isMobileOnly = window.matchMedia(
          removeAtMedia(theme.breakpoints.only("mobile")),
        ).matches;

        const results = orderedRecommendedArticles.reduce<ArticleRecommendation["results"]>(
          (sortedArticleIds, { node: article }) => {
            /*
              Note: Add check of isV2SupportedArticle here to ensure no non-migrated
               articles get included in the list
             */
            const canAddArticle =
              !(
                sortedArticleIds.infiniteScrollArticleIds.includes(article.entityId) ||
                sortedArticleIds.moreOnThisTopicWidgetIds.includes(article.entityId)
              ) && isV2SupportedArticle(article);

            const isWithinInfinityScrollCapacity =
              sortedArticleIds.infiniteScrollArticleIds.length < (isMobileOnly ? 5 : 2);

            const canAddToInfinityScroll =
              isWithinInfinityScrollCapacity &&
              !isAcknowledgementRequiredArticle(article) &&
              !isExcludedSection(article) &&
              !hasRedirectSetupToExternalDomain(article) &&
              !isScmpPlusPaywallArticle(article) &&
              passMagazinesStyleArticleTypeChecker(article);

            if (canAddToInfinityScroll && canAddArticle) {
              sortedArticleIds.infiniteScrollArticleIds.push(article.entityId);
            } else if (canAddArticle) {
              sortedArticleIds.moreOnThisTopicWidgetIds.push(article.entityId);
            }

            if (sortedArticleIds?.infiniteScrollArticleMap) {
              sortedArticleIds.infiniteScrollArticleMap[article.entityId] = article;
            }
            return sortedArticleIds;
          },
          {
            infiniteScrollArticleIds: firstFilteredMoreOnThisArticle
              ? [firstFilteredMoreOnThisArticle.entityId]
              : [],
            infiniteScrollArticleMap: firstFilteredMoreOnThisArticle
              ? {
                  [firstFilteredMoreOnThisArticle.entityId]: firstFilteredMoreOnThisArticle,
                }
              : {},
            moreOnThisTopicWidgetIds: [],
          },
        );

        return {
          allArticleIds: [
            article.entityId,
            ...results.infiniteScrollArticleIds,
            ...results.moreOnThisTopicWidgetIds,
          ],
          firstFilteredMoreOnThisArticleEntityId: firstFilteredMoreOnThisArticle?.entityId,
          recommendationId: aiEngineData.data.rec_id,
          results,
        };

        function passMagazinesStyleArticleTypeChecker(article: helpersCheckIsStyleArticle$key) {
          // Infinite scrolling can have the same magazine style articles
          if (!isCurrentMagazinesStyleArticle) return true;
          return checkIsStyleArticle(article);
        }

        function hasRedirectSetupToExternalDomain(article: PartialArticle) {
          if (!article?.urlRedirect?.toUrl) return false;
          try {
            return new URL(article.urlRedirect.toUrl).host !== data.scmpDomains.SCMP;
          } catch {
            // handle the toUrl is relative url without proper host
            return false;
          }
        }

        function isExcludedSection(article: PartialArticle) {
          const mainSection = first(article?.sections);
          if (!mainSection) return false;

          const excludedSectionEntityIds = new Set<string>([
            sectionData.photo.entityId,
            sectionData.sport.racing.entityId,
            sectionData.video.entityId,
          ]);

          return mainSection.value?.some(
            value => value && excludedSectionEntityIds.has(value.entityId),
          );
        }

        function isV2SupportedArticle(article: PartialArticle) {
          const isExcludedArticleType = () => {
            const mainArticleTypes = first(article?.types);
            if (!mainArticleTypes?.value) return false;

            const excludedArticleTypeEntityIds = new Set<string>(
              ExcludedArticleTypesInAIEngine.map(element => element.entityId),
            );

            const firstArticleTypeValue = first(mainArticleTypes.value)?.entityId ?? "";
            const secondArticleTypeValue =
              mainArticleTypes.value.length > 1 ? (mainArticleTypes.value[1]?.entityId ?? "") : "";

            return (
              excludedArticleTypeEntityIds.has(firstArticleTypeValue) ||
              excludedArticleTypeEntityIds.has(secondArticleTypeValue)
            );
          };

          const isStarlingArticle = () =>
            ExcludedArticleEntityIdsInAIEngine.includes(article?.entityId ?? "");

          return !isExcludedArticleType() && !isStarlingArticle();
        }

        function isAcknowledgementRequiredArticle(article: { entityId: string }) {
          return acknowledgementGateArticleIds.has(article.entityId);
        }

        function isScmpPlusPaywallArticle(article: PartialArticle) {
          return (article?.paywallTypes ?? [])
            .map(paywallType => paywallType?.entityId ?? "")
            .includes(paywall.scmpPlus.entityId);
        }
      },
      {
        allArticleIds: [],
        firstFilteredMoreOnThisArticleEntityId: "",
        recommendationId: "",
        results: {
          infiniteScrollArticleIds: [],
          moreOnThisTopicWidgetIds: [],
        },
      },
    );

  useEffect(() => {
    void executeFetchRecommendedArticles();
    return () => {
      disposableReference?.current?.dispose();
    };
  }, [executeFetchRecommendedArticles]);

  return { articleRecommendation };
};
