import { formatInTimeZone, notEmpty } from "@product/scmp-sdk";
import first from "lodash/first";
import last from "lodash/last";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { JsonLd } from "react-schemaorg";
import type { BlogPosting, LiveBlogPosting, NewsArticle } from "schema-dts";

import { getAbsoluteUrl } from "shared/lib/utils";

import { ISO8601DateTimeFormat } from "scmp-app/components/article/article-schema/consts";
import { useArticleSchemaContext } from "scmp-app/components/article/article-schema/contexts";
import type { SwGCompatibleSchema } from "scmp-app/components/article/article-schema/types";
import type { liveBlogPostingLiveArticles$key } from "scmp-app/queries/__generated__/liveBlogPostingLiveArticles.graphql";

type Props = {
  reference: liveBlogPostingLiveArticles$key;
};

export const LiveBlogPostingSchema: FunctionComponent<Props> = ({ reference: reference_ }) => {
  const { schemaData } = useArticleSchemaContext();

  const content = useFragment(
    graphql`
      fragment liveBlogPostingLiveArticles on Query
      @argumentDefinitions(entityId: { type: "String!" }, entityUuid: { type: "String!" }) {
        article(filter: { entityId: $entityId }) {
          urlAlias
        }
        liveArticle(filter: { entityUuid: $entityUuid }) {
          customTimezone
          liveContents {
            entityId
            publishedDate
            title
            body {
              text
            }
          }
        }
      }
    `,
    reference_,
  );

  const coverageStartTime = useMemo(() => {
    if (!notEmpty(content.liveArticle?.liveContents)) return;
    const article = first(content.liveArticle?.liveContents ?? []);
    return article
      ? formatInTimeZone(
          article.publishedDate ?? 0,
          ISO8601DateTimeFormat,
          content.liveArticle?.customTimezone ?? "Asia/Hong_Kong",
        )
      : undefined;
  }, [content.liveArticle?.customTimezone, content.liveArticle?.liveContents]);

  const coverageEndTime = useMemo(() => {
    if (!notEmpty(content.liveArticle?.liveContents)) return;
    const article = last(content.liveArticle?.liveContents ?? []);
    return article
      ? formatInTimeZone(
          article.publishedDate ?? 0,
          ISO8601DateTimeFormat,
          content.liveArticle?.customTimezone ?? "Asia/Hong_Kong",
        )
      : undefined;
  }, [content.liveArticle?.customTimezone, content.liveArticle?.liveContents]);

  const liveBlogUpdate = useMemo<BlogPosting[] | undefined>(() => {
    if (!notEmpty(content.liveArticle?.liveContents)) return;
    return content.liveArticle?.liveContents.map(article => ({
      "@type": "BlogPosting",
      articleBody: article?.body?.text ?? "",
      datePublished: article?.publishedDate
        ? formatInTimeZone(
            article.publishedDate ?? 0,
            ISO8601DateTimeFormat,
            content.liveArticle?.customTimezone ?? "Asia/Hong_Kong",
          )
        : "",
      headline: article?.title ?? "",
      publisher: schemaData?.publisher,
      url: `${getAbsoluteUrl(content.article.urlAlias)}#live-${article?.entityId ?? ""}`,
    }));
  }, [
    content.article.urlAlias,
    content.liveArticle?.customTimezone,
    content.liveArticle?.liveContents,
    schemaData?.publisher,
  ]);

  return schemaData ? (
    <JsonLd<SwGCompatibleSchema<NewsArticle, LiveBlogPosting>>
      item={{
        "@context": "https://schema.org",
        "@type": ["NewsArticle", "LiveBlogPosting"],
        coverageEndTime,
        coverageStartTime,
        liveBlogUpdate,
        ...schemaData,
      }}
    />
  ) : null;
};

LiveBlogPostingSchema.displayName = "LiveBlogPostingSchema";
