import { useUnmountEffect } from "@react-hookz/web";
import { useAtomValue } from "jotai";
import type { FunctionComponent } from "react";
import { Fragment, memo, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { ClientSideSuspense } from "shared/components/common/client-side-suspense";

import { AppFooter } from "scmp-app/components/app-footer";
import { ArticleCommentsDrawer } from "scmp-app/components/article/article-comments-drawer";
import { ArticleDivider } from "scmp-app/components/article/article-divider";
import { ArticleFooter } from "scmp-app/components/article/article-footer";
import { ArticlePaywallUnlockProvider } from "scmp-app/components/article/article-paywall-unlock/contexts";
import { PianoPaywall } from "scmp-app/components/article/article-piano-paywall";
import { useArticlePianoPaywallContext } from "scmp-app/components/article/article-piano-paywall/contexts";
import { ArticleRender } from "scmp-app/components/article/article-render";
import { checkIsPostiesArticle } from "scmp-app/components/article/article-render/posties/helpers";
import { checkIsStyleArticle } from "scmp-app/components/article/article-render/style-article/helper";
import { ArticleSpeechPlayer } from "scmp-app/components/article/article-speech";
import { ArticleSpeechContextProvider } from "scmp-app/components/article/article-speech/contexts";
import { ContentReadMoreContextProvider } from "scmp-app/components/content/content-read-more";
import { InlineOutstreamAdSlotContextProvider } from "scmp-app/components/schema-render/common/inline-ad-slot/outstream/div-id";
import { appAtom } from "scmp-app/lib/app/atoms";
import { useInfiniteScrollTriggerReference } from "scmp-app/lib/hooks";
import type { ShareContentUnlocked } from "scmp-app/pages/article/types";
import type { articleListArticle$key } from "scmp-app/queries/__generated__/articleListArticle.graphql";

import { ArticleListContextProvider } from "./contexts";
import {
  useArticlePagination,
  useArticlePaginationChecker,
  useArticleRecommendation,
  useHandlePaginationNavigationHistory,
  useOnlyOnePlayingPlayer,
  useTrackPaginationCurrentArticle,
} from "./hooks";
import { Item, StyledLoading } from "./styles";

type Props = {
  reference: articleListArticle$key;
  shareContentUnlocked?: ShareContentUnlocked;
};

export const ArticleList: FunctionComponent<Props> = ({
  reference: reference_,
  shareContentUnlocked,
}) => {
  const article = useFragment(
    graphql`
      fragment articleListArticle on Article
      @argumentDefinitions(
        customContents: { type: "[CustomContentInput]", defaultValue: [] }
        bodyType: { type: "BodyFormatTypeEnum", defaultValue: PARAGRAPH }
      ) {
        entityId
        ...articleDividerArticle
        ...articleFooterArticle
        ...articlePaginationCheckerArticle
        ...articlePaginationNavigationHistoryArticle
          @arguments(customContents: $customContents, bodyType: $bodyType)
        ...articleRecommendationArticle
        ...articlePianoPaywallContent
        ...articleRenderArticle @arguments(customContents: $customContents, bodyType: $bodyType)
        ...helpersCheckIsPostiesArticle
        ...helpersCheckIsStyleArticle
        ...contextsContentReadMoreContextProviderContent
        ...contextsArticleListContextProviderArticle
      }
    `,
    reference_,
  );

  const { navigatorInfo } = useAtomValue(appAtom);
  const isBot = navigatorInfo?.isBot ?? true; // Prevent infinite scroll for bots in the beginning
  const { articleRecommendation } = useArticleRecommendation(article);
  const { data, hasNext, isLoading, loadNext } = useArticlePagination(
    articleRecommendation?.results.infiniteScrollArticleIds ?? [],
    articleRecommendation.results.infiniteScrollArticleMap,
  );
  const { articlePianoPaywallStateMap, resetArticlePianoPaywallStateMap } =
    useArticlePianoPaywallContext();

  type Item = typeof article;
  const items = useMemo(() => [article, ...data] as [Item, ...Item[]], [article, data]);

  const { bindItemReference, currentArticle } = useTrackPaginationCurrentArticle(items);
  useHandlePaginationNavigationHistory(currentArticle, articleRecommendation);

  useUnmountEffect(() => resetArticlePianoPaywallStateMap?.());

  const isLoadingPaywall = useMemo(() => {
    const doesNotRequirePaywall =
      articlePianoPaywallStateMap?.[currentArticle.entityId] === "paywallNotRequired";
    if (doesNotRequirePaywall) return false;

    return !(articlePianoPaywallStateMap?.[currentArticle.entityId] ?? "").startsWith("shown");
  }, [currentArticle.entityId, articlePianoPaywallStateMap]);

  const { infiniteScrollTriggerReference } = useInfiniteScrollTriggerReference({
    hasNextPage: hasNext,
    isLoading: isLoading || isLoadingPaywall,
    onLoadMore: loadNext,
  });

  const { shouldStopPagination } = useArticlePaginationChecker(items);

  const shouldContinuePagination = !isBot && !shouldStopPagination && hasNext;

  const getFooterVariant = (item: Item) => {
    switch (true) {
      case checkIsPostiesArticle(item):
        return "posties";
      case checkIsStyleArticle(item):
        return "magazines-style";
      default:
        return;
    }
  };

  useOnlyOnePlayingPlayer();

  return (
    <InlineOutstreamAdSlotContextProvider>
      <ArticleListContextProvider reference={article}>
        <ArticlePaywallUnlockProvider shareContentUnlocked={shareContentUnlocked}>
          <ArticleSpeechContextProvider>
            <ArticleCommentsDrawer />
            <ArticleSpeechPlayer />

            {items.map((item, index) => (
              <Fragment key={index}>
                {index > 0 && <ArticleDivider reference={article} />}
                <Item ref={bindItemReference(index)}>
                  <ContentReadMoreContextProvider reference={item}>
                    <MemorizedArticleRender reference={item} />
                  </ContentReadMoreContextProvider>
                </Item>
                <ClientSideSuspense>
                  <PianoPaywall reference={item} />
                  {shouldContinuePagination && index === items.length - 1 && (
                    <>
                      <ArticleDivider reference={article} />
                      <StyledLoading ref={infiniteScrollTriggerReference} />
                    </>
                  )}
                  {!shouldContinuePagination && index === items.length - 1 && (
                    <>
                      {!isBot && (
                        <ArticleFooter
                          articleRecommendation={{
                            allArticleIds: articleRecommendation.allArticleIds,
                            articleIds: articleRecommendation.results.moreOnThisTopicWidgetIds,
                            recommendationId: articleRecommendation.recommendationId,
                          }}
                          divider={<ArticleDivider reference={article} />}
                          reference={article}
                        />
                      )}
                      <AppFooter variant={getFooterVariant(item)} />
                    </>
                  )}
                </ClientSideSuspense>
              </Fragment>
            ))}
          </ArticleSpeechContextProvider>
        </ArticlePaywallUnlockProvider>
      </ArticleListContextProvider>
    </InlineOutstreamAdSlotContextProvider>
  );
};

const MemorizedArticleRender = memo(ArticleRender);

ArticleList.displayName = "ArticleList";
