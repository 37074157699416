import { findFirstNonEmptyString, formatInTimeZone, notEmpty } from "@product/scmp-sdk";
import first from "lodash/first";
import last from "lodash/last";
import type { FunctionComponent } from "react";
import { useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import type {
  CorrectionComment,
  ImageObject,
  NewsMediaOrganization,
  Person,
  Place,
  WebPageElement,
} from "schema-dts";

import { getAbsoluteUrl, getAssetPrefix } from "shared/lib/utils";

import { PianoMeteringContainerClass } from "scmp-app/components/article/article-paywall-container/consts";
import { useDetermineHeadlineTag } from "scmp-app/components/content/content-headline/content-headline-tag/hooks";
import type { articleSchemaContent$key } from "scmp-app/queries/__generated__/articleSchemaContent.graphql";

import { AdvertiserContentArticleSchema } from "./advertiser-content-article";
import { AnalysisNewsArticleSchema } from "./analysis-news-article";
import { BackgroundNewsArticleSchema } from "./background-news-article";
import { ArticleTypeToSchemaType, ISO8601DateTimeFormat, SchemaType } from "./consts";
import { ArticleSchemaContext } from "./contexts";
import { LiveBlogPostingSchema } from "./live-blog-posting";
import { OpinionNewsArticleSchema } from "./opinion-news-article";
import { ReportageNewsArticleSchema } from "./reportage-news-article";
import { ReviewNewsArticleSchema } from "./review-news-article";
import type { CreativeWorkUnionType } from "./types";

type Props = {
  reference: articleSchemaContent$key;
};

export const ArticleSchema: FunctionComponent<Props> = ({ reference: reference_ }) => {
  const content = useFragment(
    graphql`
      fragment articleSchemaContent on Query
      @argumentDefinitions(
        entityId: { type: "String!" }
        entityUuid: { type: "String!" }
        bodyType: { type: "BodyFormatTypeEnum", defaultValue: PARAGRAPH }
      ) {
        article(filter: { entityId: $entityId }) {
          ...hooksContentHeadlineTagContent
          authorLocations
          contentLock
          createdDate
          headline
          publishedDate
          socialHeadline
          sponsorType
          updatedDate
          urlAlias
          authors {
            location
            name
            types
            urlAlias
          }
          body(customContents: $customContents, type: $bodyType) {
            text
          }
          summary {
            text
          }
          subHeadline {
            text
          }
          corrections {
            correction {
              text
            }
            timestamp
          }
          images {
            isSlideshow
            height
            width
            url
          }
          sections {
            value {
              name
            }
          }
          types {
            value {
              name
            }
          }
        }

        ...liveBlogPostingLiveArticles @arguments(entityId: $entityId, entityUuid: $entityUuid)
      }
    `,
    reference_,
  );

  const mainAuthor = useMemo(() => first(content.article.authors ?? []), [content.article.authors]);

  const mainType = useMemo(() => {
    if (content.article.sponsorType) return SchemaType.AdvertiserContentArticle;
    const articleTypes = (content.article.types ?? [])
      .flatMap(type => (type?.value ?? []).map(value => value?.name ?? ""))
      .filter(type => notEmpty(type));
    const mainType = first(articleTypes) ?? "";
    return ArticleTypeToSchemaType[mainType] ?? SchemaType.ReportageNewsArticle;
  }, [content.article.sponsorType, content.article.types]);

  const noBylinesPolicy = useMemo(
    () =>
      (mainAuthor?.types ?? []).includes("no_byline")
        ? "https://www.scmp.com/policies-and-standards#no-byline-policy-explanation"
        : undefined,
    [mainAuthor?.types],
  );

  const publisher = useMemo<NewsMediaOrganization>(
    () => ({
      "@type": "NewsMediaOrganization",
      logo: {
        "@type": "ImageObject",
        height: {
          "@type": "QuantitativeValue",
          value: 512,
        },
        url: `${getAssetPrefix()}/icons/scmp-icon-512x512.png`,
        width: {
          "@type": "QuantitativeValue",
          value: 512,
        },
      },
      name: "South China Morning Post",
      noBylinesPolicy,
    }),
    [noBylinesPolicy],
  );

  const author = useMemo<Person>(
    () => ({
      "@type": "Person",
      name: mainAuthor?.name ?? "SCMP Staff",
      sameAs: `${getAbsoluteUrl(mainAuthor?.urlAlias ?? "")}`,
    }),
    [mainAuthor?.name, mainAuthor?.urlAlias],
  );

  const locationCreated = useMemo<Place>(() => {
    const authorsLocation = first(content.article.authors ?? [])?.location ?? "";
    const articleLocation = first(content.article.authorLocations ?? []) ?? "";
    return {
      "@type": "Place",
      name: findFirstNonEmptyString(
        articleLocation.toLowerCase() === "not available" ? "" : articleLocation,
        authorsLocation,
        "Hong Kong",
      ),
    };
  }, [content.article.authorLocations, content.article.authors]);

  const sanitizedDescription = useMemo(() => {
    const pickedSummary = first(content.article.summary?.text?.split("\n"));
    const pickedSubHeadline = first(content.article.subHeadline?.text?.split("\n"));
    const pickedBody = first(content.article.body?.text?.split("\n"));
    return findFirstNonEmptyString(pickedSummary, pickedSubHeadline, pickedBody);
  }, [
    content.article.summary?.text,
    content.article.subHeadline?.text,
    content.article.body?.text,
  ]);

  const correction = useMemo<CorrectionComment | undefined>(() => {
    const firstCorrection = first(content.article.corrections ?? []);
    return firstCorrection
      ? {
          "@type": "CorrectionComment",
          datePublished: formatInTimeZone(new Date(firstCorrection.timestamp), "yyyy-MM-dd"),
          name: firstCorrection.correction?.text?.replaceAll(/<\/?[^>]+>/gi, ""),
        }
      : undefined;
  }, [content.article.corrections]);

  const mainSectionNames = useMemo(() => {
    if (!notEmpty(content.article.sections)) return ["News"];
    return first(content.article.sections)?.value?.map(section => section?.name);
  }, [content.article.sections]);

  const { name } = useDetermineHeadlineTag(content.article, false) ?? {};
  const computeHeadlineWithFlag = useCallback(
    (articleHeadline: string) => {
      if (!notEmpty(articleHeadline)) return "";
      return name ? `${name} | ${articleHeadline}` : articleHeadline;
    },
    [name],
  );

  const hasPart = useMemo<undefined | WebPageElement>(
    () =>
      content.article.contentLock
        ? {
            "@type": "WebPageElement",
            cssSelector: `.${PianoMeteringContainerClass}`,
            isAccessibleForFree: "False",
          }
        : undefined,
    [content.article.contentLock],
  );

  const image = useMemo<ImageObject | undefined>(() => {
    if (!notEmpty(content.article.images)) return;
    const images = content.article.images?.filter(img => notEmpty(img)) ?? [];
    const mainImage = images.find(img => !!img?.isSlideshow) ?? first(images);
    return mainImage
      ? {
          "@type": "ImageObject",
          height: {
            "@type": "QuantitativeValue",
            value: mainImage?.height ?? 0,
          },
          url: mainImage?.url ?? "",
          width: {
            "@type": "QuantitativeValue",
            value: mainImage?.width ?? 0,
          },
        }
      : undefined;
  }, [content.article.images]);

  const schemaData = useMemo<CreativeWorkUnionType>(
    () => ({
      alternativeHeadline: computeHeadlineWithFlag(content.article.socialHeadline ?? ""),
      articleBody: content.article.body?.text ?? "",
      articleSection: last(mainSectionNames),
      author,
      correction,
      dateCreated: formatInTimeZone(content.article.createdDate ?? 0, ISO8601DateTimeFormat),
      dateModified: formatInTimeZone(
        content.article.updatedDate ?? content.article.createdDate ?? 0,
        ISO8601DateTimeFormat,
      ),
      datePublished: formatInTimeZone(
        content.article.publishedDate ?? content.article.createdDate ?? 0,
        ISO8601DateTimeFormat,
      ),
      description: sanitizedDescription,
      genre: first(mainSectionNames),
      hasPart,
      headline: computeHeadlineWithFlag(content.article.headline ?? ""),
      image,
      inLanguage: "en-GB",
      isAccessibleForFree: content.article.contentLock ? "False" : "True",
      locationCreated,
      mainEntityOfPage: getAbsoluteUrl(content.article.urlAlias),
      publisher,
      publishingPrinciples: "https://www.scmp.com/policies-and-standards",
    }),
    [
      computeHeadlineWithFlag,
      content.article.socialHeadline,
      content.article.body?.text,
      content.article.createdDate,
      content.article.updatedDate,
      content.article.publishedDate,
      content.article.headline,
      content.article.contentLock,
      content.article.urlAlias,
      mainSectionNames,
      author,
      correction,
      sanitizedDescription,
      hasPart,
      image,
      locationCreated,
      publisher,
    ],
  );

  const componentMap: Record<string, JSX.Element | null> = {
    AdvertiserContentArticle: <AdvertiserContentArticleSchema />,
    AnalysisNewsArticle: <AnalysisNewsArticleSchema />,
    BackgroundNewsArticle: <BackgroundNewsArticleSchema />,
    LiveBlogPosting: content ? <LiveBlogPostingSchema reference={content} /> : null,
    NullNewsArticle: null,
    OpinionNewsArticle: <OpinionNewsArticleSchema />,
    ReportageNewsArticle: <ReportageNewsArticleSchema />,
    ReviewNewsArticle: <ReviewNewsArticleSchema />,
  };

  const renderJsonLd = () => (
    <ArticleSchemaContext.Provider value={{ schemaData }}>
      {componentMap[mainType]}
    </ArticleSchemaContext.Provider>
  );

  return renderJsonLd();
};

ArticleSchema.displayName = "ArticleSchema";
