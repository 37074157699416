import { createContext, useContext } from "react";
import type { CreativeWork } from "schema-dts";

type ContextValue = {
  schemaData: Omit<CreativeWork, "@type"> | undefined;
};

export const ArticleSchemaContext = createContext<ContextValue>({
  schemaData: undefined,
});

export const useArticleSchemaContext = () => useContext(ArticleSchemaContext);
