import { theme, useResponsive } from "@product/scmp-sdk";
import { useAtom, useAtomValue } from "jotai";
import type { FunctionComponent } from "react";
import { useEffect, useMemo } from "react";

import { checkIsStyleArticle } from "scmp-app/components/article/article-render/style-article/helper";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { currentArticleAtom } from "scmp-app/lib/current-item";
import { yieldToMain } from "scmp-app/lib/yield";

import { contentShareWidgetDrawerAtom } from "./atoms";
import { StyledArticleShareWidget, StyledDynamicDrawer } from "./styles";

export type Variant = "default" | "magazines-style";

export const ContentShareWidgetDrawer: FunctionComponent = () => {
  const currentArticle = useAtomValue(currentArticleAtom);
  const [contentShareWidgetDrawerState, setContentShareWidgetDrawer] = useAtom(
    contentShareWidgetDrawerAtom,
  );

  const isTabletUp = useResponsive(theme.breakpoints.up("tablet"), true);
  useEffect(() => {
    setContentShareWidgetDrawer(props => ({ ...props, isOpen: false }));
  }, [isTabletUp, setContentShareWidgetDrawer]);

  useEffect(() => {
    if (!contentShareWidgetDrawerState.isOpen) return;

    void (async () => {
      await yieldToMain({ priority: "background" });
      sendGA4Tracking({
        action: "click",
        category: "share",
        customized_parameters: {
          action_type: "open_sharing_drawer",
          position: contentShareWidgetDrawerState.position,
        },
        subcategory: "",
      });
    })();
  }, [contentShareWidgetDrawerState.isOpen, contentShareWidgetDrawerState.position]);

  const variant: Variant = useMemo(() => {
    if (currentArticle && checkIsStyleArticle(currentArticle)) {
      return "magazines-style";
    }
    return "default";
  }, [currentArticle]);

  if (!currentArticle) return null;

  return (
    <StyledDynamicDrawer
      $variant={variant}
      anchor="bottom"
      onClose={() => setContentShareWidgetDrawer(props => ({ ...props, isOpen: false }))}
      open={contentShareWidgetDrawerState.isOpen}
      PaperProps={{
        sx: {
          alignItems: "center",
          border: "1px solid #000",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          display: "flex",
        },
      }}
      title="Share"
      withClose
    >
      <StyledArticleShareWidget
        onClick={() => {
          setTimeout(
            () => setContentShareWidgetDrawer(props => ({ ...props, isOpen: false })),
            500,
          );
        }}
        position={contentShareWidgetDrawerState.position ?? "article_adhesive"}
        reference={currentArticle}
        variant="defaultMobile"
      />
    </StyledDynamicDrawer>
  );
};

ContentShareWidgetDrawer.displayName = "ContentShareWidgetDrawer";
