import { notEmpty } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import isString from "lodash/isString";
import type { NextPageContext, PageProps } from "next";
import { memo } from "react";
import { graphql, usePreloadedQuery } from "react-relay";
import { withRelay } from "relay-nextjs";
import type { RelayComponent } from "relay-nextjs";

import { useTopBannerAdSlot } from "scmp-app/components/advertisement/ad-slots/top-banner-ad-slot/hooks";
import type { AppBarVariant } from "scmp-app/components/app-bar/types";
import type { Application } from "scmp-app/components/app-initializer/helpers";
import { ArticleLinkingData } from "scmp-app/components/article/article-linking-data";
import { ArticleList } from "scmp-app/components/article/article-list";
import { DefaultCustomContents } from "scmp-app/components/article/article-render/consts";
import { useArticleBaseAdvertisingInfo } from "scmp-app/components/article/article-render/hooks";
import { PlusCustomContents } from "scmp-app/components/article/article-render/plus/consts";
import { StyleCustomContents } from "scmp-app/components/article/article-render/style-article/consts";
import { ArticleRenderType } from "scmp-app/components/article/article-render/types";
import { ArticleSchema } from "scmp-app/components/article/article-schema";
import { ArticleSeo } from "scmp-app/components/article/article-seo";
import { ContentShareWidgetDrawer } from "scmp-app/components/content/content-share-widget-drawer";
import { FallbackLoading } from "scmp-app/components/fallback-loading";
import { currentArticleAtom } from "scmp-app/lib/current-item";
import { createClientEnvironment } from "scmp-app/lib/relay/environment.client";
import type {
  articlePageQuery,
  BodyFormatTypeEnum,
} from "scmp-app/queries/__generated__/articlePageQuery.graphql";

import { decryptedShare } from "./helpers";
import {
  useAmpRedirect,
  useArticleAcknowledgementGateOverlay,
  useTrackContentInterest,
  useTrackPageView,
} from "./hooks";
import { Article } from "./styles";

export const query = graphql`
  query articlePageQuery(
    $entityId: String!
    $entityUuid: String!
    $customContents: [CustomContentInput]
    $bodyType: BodyFormatTypeEnum
  ) {
    article(filter: { entityId: $entityId }) {
      ...articleBaseAdvertisingInfoArticle
      ...articleListArticle @arguments(customContents: $customContents, bodyType: $bodyType)
      ...articleSeoArticle @arguments(customContents: $customContents, bodyType: $bodyType)
      ...helpersCheckIsPostiesArticle
      ...hooksArticleAcknowledgementGateOverlayArticle
      ...helpersCheckIsPostiesArticle
      ...hooksTrackPageViewArticle
      ...hooksContentInterestArticle
      ...articleLinkingDataContent
      ...hooksAmpRedirectArticle
      entityId
    }
    ...articleSchemaContent
      @arguments(entityId: $entityId, entityUuid: $entityUuid, bodyType: $bodyType)
  }
`;

const ArticlePage: RelayComponent<PageProps, articlePageQuery> = ({
  appBarConfiguration,
  preloadedQuery,
  shareContentUnlocked,
}) => {
  const data = usePreloadedQuery(query, preloadedQuery);
  const advertisingInfo = useArticleBaseAdvertisingInfo(data.article);
  const currentArticle = useAtomValue(currentArticleAtom);
  const { isAcknowledgementRequired, renderAcknowledgementGateOverlay } =
    useArticleAcknowledgementGateOverlay(data.article);

  useAmpRedirect(data.article);
  useTrackPageView(currentArticle ?? data.article, shareContentUnlocked);
  useTrackContentInterest(currentArticle ?? data.article);
  useTopBannerAdSlot({
    desktop: null,
    mobile: appBarConfiguration?.hasMobileAd
      ? {
          ...advertisingInfo,
          adUnit: "m_banner3",
          autoRefreshOptions: {
            scrollBackRefreshOptions: {
              slotArticleEntityId: data.article.entityId,
            },
          },
        }
      : null,
  });

  return (
    <>
      <ArticleSeo reference={currentArticle ?? data.article} />
      <ContentShareWidgetDrawer />
      <ArticleLinkingData reference={data.article} />
      <ArticleSchema reference={data} />
      {renderAcknowledgementGateOverlay()}
      {/* TODO: workaround for fixing https://scmp-product-tech.atlassian.net/browse/SCMPWEB-3430, third party should not relied on the application level */}
      <Article $isShowArticle={!isAcknowledgementRequired} className="article-list">
        <List reference={data.article} shareContentUnlocked={shareContentUnlocked} />
      </Article>
    </>
  );
};

const List = memo(ArticleList);

ArticlePage.displayName = "ArticlePage";

const getPageProps = (context: NextPageContext) => {
  const appBarVariant = context.query.appBarVariant as AppBarVariant;
  const application = context.query.application as Application;
  const renderType = context.query.renderType as ArticleRenderType | undefined;
  const isSectionStyle = appBarVariant === "scmp/magazines-style";

  const shareContentUnlocked =
    isString(context.query.entityId) && isString(context.query?.share)
      ? decryptedShare(context)
      : undefined;

  const pageProps: PageProps = {
    animateKey: `article-${context.query.entityId?.toString() ?? ""}`,
    appBarConfiguration: {
      hasMobileAd: appBarVariant === "scmp/generic-light-with-banner-ad",
      variant: appBarVariant,
    },
    appInitializerConfiguration: {
      application,
    },
    contentConfiguration: {
      // check for article is generic and use the new padding config
      responsivePadding:
        notEmpty(renderType) && renderType === ArticleRenderType.Generic
          ? {
              desktopUp: 12,
              mobileUp: 4,
              tabletUp: 12,
            }
          : {
              desktopUp: 32,
              mediumDesktopUp: 52,
              tabletUp: 12,
            },
    },
    headerConfiguration: {
      responsiveFeatures: isSectionStyle
        ? {
            desktopUp: {
              default: {
                left: ["logo-link-style", "text-link-style"],
                right: ["subscription", "search", "my-news", "dashboard"],
              },
            },
            largeDesktopUp: {
              default: {
                left: ["logo-link-style", "text-link-style"],
                right: ["subscription", "search", "my-news", "dashboard"],
              },
            },
            mediumDesktopUp: {
              default: {
                left: ["logo-link-style", "text-link-style"],
                right: ["subscription", "search", "my-news", "dashboard"],
              },
            },
            mobileUp: {
              default: {
                left: ["logo-link-style", "text-link-style"],
                right: ["dashboard", "hamburger-menu"],
              },
              nonSubscriberSwapped: {
                left: ["logo-link-style"],
                right: [
                  "subscription",
                  "content-share",
                  "content-bookmark",
                  "content-comment",
                  "hamburger-menu",
                ],
              },
              swapped: {
                left: ["logo-link-style"],
                right: ["content-share", "content-bookmark", "content-comment", "hamburger-menu"],
              },
            },
            tabletUp: {
              default: {
                left: ["logo-link-style", "text-link-style"],
                right: ["subscription", "my-news", "dashboard", "hamburger-menu"],
              },
            },
          }
        : {
            mobileUp: {
              default: { left: ["hamburger-menu"], right: ["dashboard"] },
              nonSubscriberSwapped: {
                left: ["hamburger-menu", "logo-link"],
                right: ["subscription", "content-share", "content-bookmark", "content-comment"],
              },
              swapped: {
                left: ["hamburger-menu", "logo-link"],
                right: ["article-speech", "content-share", "content-bookmark", "content-comment"],
              },
            },
          },
    },
    shareContentUnlocked,
  };

  if (appBarVariant === "scmp/magazines-style") {
    pageProps.pageConfiguration = {
      ...pageProps.pageConfiguration,
      responsivePadding: {
        desktopUp: 40,
        mobileUp: 16,
        tabletUp: 20,
      },
    };
    pageProps.contentConfiguration = {
      ...pageProps.contentConfiguration,
      responsivePadding: {
        desktopUp: 135,
        mobileUp: 24,
        tabletUp: 70,
      },
    };
  }

  return pageProps;
};

export default withRelay(ArticlePage, query, {
  clientSideProps: context => getPageProps(context),
  createClientEnvironment: () => createClientEnvironment(),
  createServerEnvironment: async context => {
    const { createServerEnvironment } = await import("scmp-app/lib/relay/environment.server");
    return createServerEnvironment(context.req?.cookies);
  },
  fallback: <FallbackLoading />,
  serverSideProps: context => Promise.resolve(getPageProps(context)),
  variablesFromContext(context) {
    const appBarVariant = context.query.appBarVariant as AppBarVariant;

    const getCustomContents = () => {
      switch (appBarVariant) {
        case "scmp/magazines-style":
          return StyleCustomContents;
        case "scmp/plus":
          return PlusCustomContents;
        default:
          return DefaultCustomContents;
      }
    };

    const bodyType: BodyFormatTypeEnum =
      appBarVariant === "scmp/magazines-style" ? "PARAGRAPHANDMEDIA" : "PARAGRAPH";

    return isString(context.query.entityId) && isString(context.query.entityUuid)
      ? {
          bodyType,
          customContents: getCustomContents(),
          entityId: context.query.entityId,
          entityUuid: context.query.entityUuid,
        }
      : {
          bodyType,
          customContents: [],
          entityId: "",
          entityUuid: "",
        };
  },
});
