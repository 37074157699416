import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import { setFullViewportWidthWithMaxPageWidthStyles } from "shared/lib/styles";

import { ArticleRenderContainer } from "scmp-app/components/article/article-render/styles";
import { Loading } from "scmp-app/components/common/loading";

export const StyledLoading = styled(Loading)`
  margin-block: 40px;
`;

export const Divider = styled.div`
  position: relative;
  z-index: 2;

  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;

  block-size: 1px;

  background-color: #dddddd;

  &::before,
  &::after {
    content: "";

    display: block;
    flex: none;

    inline-size: 30px;
    block-size: 5px;

    outline: #ffffff solid 4px;
  }

  &::before {
    background-color: #ffca05;
  }

  &::after {
    background-color: #001246;
  }

  &:last-child {
    display: none;
  }

  ${theme.breakpoints.only("mobile")} {
    ${setFullViewportWidthWithMaxPageWidthStyles()}
  }
`;

export const Item = styled.div`
  position: relative;

  &:first-of-type ${ArticleRenderContainer} > *:first-child {
    padding-block-start: 0;
  }
`;
