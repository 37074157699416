import { notEmpty } from "@product/scmp-sdk";
import {
  useAsync,
  useDeepCompareEffect,
  useDeepCompareMemo,
  useIsomorphicLayoutEffect,
  useMountEffect,
  usePrevious,
  useSyncedRef,
} from "@react-hookz/web";
import { useRouter } from "next/router";
import qs from "qs";
import { useEffect, useMemo, useRef, useState } from "react";
import type { Disposable } from "react-relay";
import { fetchQuery, graphql, useFragment, useRelayEnvironment } from "react-relay";
import { createOperationDescriptor, getRequest } from "relay-runtime";

import { tracking } from "shared/data";
import { article as articleData } from "shared/data/article";

import { DefaultCustomContents } from "scmp-app/components/article/article-render/consts";
import { getArticleType } from "scmp-app/components/article/article-render/helpers";
import { checkIsPostiesArticle } from "scmp-app/components/article/article-render/posties/helpers";
import { StyleCustomContents } from "scmp-app/components/article/article-render/style-article/consts";
import { checkIsStyleArticle } from "scmp-app/components/article/article-render/style-article/helper";
import { useTrackCurrentItem } from "scmp-app/lib/current-item";
import type { articlePaginationCheckerArticle$key } from "scmp-app/queries/__generated__/articlePaginationCheckerArticle.graphql";
import type {
  articlePaginationNavigationHistoryArticle$data,
  articlePaginationNavigationHistoryArticle$key,
} from "scmp-app/queries/__generated__/articlePaginationNavigationHistoryArticle.graphql";
import type {
  articlePaginationQuery,
  articlePaginationQuery$data,
  BodyFormatTypeEnum,
} from "scmp-app/queries/__generated__/articlePaginationQuery.graphql";

import type { ArticleRecommendation } from "./types";

export const useArticlePagination = (
  entityIds: string[],
  articleMap: ArticleRecommendation["results"]["infiniteScrollArticleMap"],
) => {
  const environment = useRelayEnvironment();

  const [results, setResults] = useState<
    { data: articlePaginationQuery$data; disposable: Disposable }[]
  >([]);
  const data = useMemo(() => results.map(result => result.data.article), [results]);

  const latestEntityIds = useSyncedRef(entityIds);
  const [currentIndex, setCurrentIndex] = useState(0);
  const latestCurrentIndex = useSyncedRef(currentIndex);

  const [isLoading, setIsLoading] = useState(false);
  const latestIsLoading = useSyncedRef(isLoading);

  const hasNext = useDeepCompareMemo(
    () => entityIds.length > 0 && entityIds.length !== currentIndex,
    [entityIds, currentIndex],
  );
  const latestHasNext = useSyncedRef(hasNext);

  const loadNext = useDeepCompareMemo(
    () => async () => {
      if (latestIsLoading.current || !latestHasNext.current) return;

      try {
        const entityId = entityIds[latestCurrentIndex.current];
        const query = graphql`
          query articlePaginationQuery(
            $entityId: String!
            $customContents: [CustomContentInput]
            $bodyType: BodyFormatTypeEnum
          ) {
            article(filter: { entityId: $entityId }) {
              entityId
              ...articleDividerArticle
              ...articlePaginationCheckerArticle
              ...articlePaginationNavigationHistoryArticle
                @arguments(customContents: $customContents, bodyType: $bodyType)
              ...articlePianoPaywallContent
              ...articleRenderArticle
                @arguments(customContents: $customContents, bodyType: $bodyType)
              ...helpersCheckIsPostiesArticle
              ...helpersCheckIsStyleArticle
              ...contextsContentReadMoreContextProviderContent
            }
          }
        `;

        const article = articleMap?.[entityId];

        const variables = {
          bodyType: (article && checkIsStyleArticle(article)
            ? "PARAGRAPHANDMEDIA"
            : "PARAGRAPH") as BodyFormatTypeEnum,
          customContents:
            article && checkIsStyleArticle(article) ? StyleCustomContents : DefaultCustomContents,
          entityId,
        };
        const queryRequest = getRequest(query);
        const queryDescriptor = createOperationDescriptor(queryRequest, variables);
        setIsLoading(true);
        const fetched = await fetchQuery<articlePaginationQuery>(
          environment,
          query,
          variables,
        ).toPromise();

        if (entityIds !== latestEntityIds.current) return;

        if (fetched) {
          const disposable = environment.retain(queryDescriptor);
          setResults(results => [...results, { data: fetched, disposable }]);
        }
      } finally {
        setCurrentIndex(index => index + 1);
        setIsLoading(false);
      }
    },
    [entityIds],
  );

  useDeepCompareEffect(() => {
    setIsLoading(false);
    setResults(results => {
      results.forEach(result => result.disposable.dispose());
      return [];
    });
    setCurrentIndex(0);
  }, [entityIds]);

  return { data, hasNext, isLoading, loadNext };
};

export const useTrackPaginationCurrentArticle = <T>(items: NonEmptyArray<T>) => {
  const itemElementReferences = useRef<(Element | null)[]>([]);
  const firstItem = items[0];
  const [currentArticle, setCurrentArticle] = useState<T>(firstItem);

  useIsomorphicLayoutEffect(() => {
    const intersectedIndexes = new Set<number>();

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const entryIndex = itemElementReferences.current.indexOf(entry.target);
          if (entry.isIntersecting) {
            intersectedIndexes.add(entryIndex);
          } else {
            intersectedIndexes.delete(entryIndex);
          }
          const intersectedItem = items.at(Math.max(...intersectedIndexes.values()));
          if (intersectedItem) {
            setCurrentArticle(intersectedItem);
          }
        });
      },
      { rootMargin: "0px 0px -50% 0px" },
    );

    itemElementReferences.current.forEach(reference => {
      if (!reference) return;
      observer.observe(reference);
    });

    return () => {
      itemElementReferences.current = [];
      observer.disconnect();
    };
  }, [firstItem, items]);

  const bindItemReference = (index: number) => (element: Element | null) => {
    itemElementReferences.current[index] = element;
  };

  return {
    bindItemReference,
    currentArticle,
    itemElementReferences,
  };
};

export const useHandlePaginationNavigationHistory = (
  currentArticle_: articlePaginationNavigationHistoryArticle$key,
  articleRecommendation: ArticleRecommendation,
) => {
  const currentArticle = useFragment(
    graphql`
      fragment articlePaginationNavigationHistoryArticle on Article
      @argumentDefinitions(
        customContents: { type: "[CustomContentInput]", defaultValue: [] }
        bodyType: { type: "BodyFormatTypeEnum", defaultValue: PARAGRAPH }
      ) {
        entityId
        urlAlias
        ...hooksTrackCurrentItemBase
          @arguments(customContents: $customContents, bodyType: $bodyType)
      }
    `,
    currentArticle_,
  );
  const currentArticleUrlAlias = currentArticle.urlAlias;
  const previousArticleUrlAlias = usePrevious(currentArticle.urlAlias);

  const [hasPushedHistory, setHasPushedHistory] = useState(false);
  const latestHasPushedHistory = useSyncedRef(hasPushedHistory);
  const router = useRouter();
  const { trackCurrentItem } = useTrackCurrentItem();

  const [_, { execute: pushHistory }] = useAsync(
    async (
      currentArticleUrlAlias: string,
      currentItem: articlePaginationNavigationHistoryArticle$data,
      articleRecommendation: ArticleRecommendation,
    ) => {
      const { entityId } = currentItem;
      const position = articleRecommendation.results.infiniteScrollArticleIds.indexOf(entityId);
      const articleUrl = new URL(currentArticleUrlAlias, location.href);

      const updatedQueryParameters = {
        module:
          position === -1
            ? "perpetual_scroll_0"
            : `perpetual_scroll_${position + 1}_${
                articleRecommendation.firstFilteredMoreOnThisArticleEntityId === entityId
                  ? "RM"
                  : "AI"
              }`,
        pgtype: tracking.pageType.Article,
      };

      articleUrl.search = qs.stringify(updatedQueryParameters);

      const { pathname, push, query } = router;
      await push({ pathname, query }, `${articleUrl.pathname}${articleUrl.search}`, {
        scroll: false,
        shallow: true,
      });
      trackCurrentItem(currentItem);
      setHasPushedHistory(true);
    },
  );

  useMountEffect(() => {
    trackCurrentItem(currentArticle);
    return () => trackCurrentItem();
  });

  useEffect(() => {
    if (!previousArticleUrlAlias || currentArticleUrlAlias === previousArticleUrlAlias) {
      return;
    }
    void pushHistory(currentArticleUrlAlias, currentArticle, articleRecommendation);
  }, [
    currentArticleUrlAlias,
    previousArticleUrlAlias,
    currentArticle,
    pushHistory,
    trackCurrentItem,
    articleRecommendation,
  ]);

  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (latestHasPushedHistory.current) {
        window.location.href = as;
        return false;
      }
      return true;
    });
  }, [latestHasPushedHistory, router]);
};

export const useArticlePaginationChecker = (
  items: NonEmptyArray<articlePaginationCheckerArticle$key>,
) => {
  const lastItem = useFragment<articlePaginationCheckerArticle$key>(
    graphql`
      fragment articlePaginationCheckerArticle on Article {
        ...helpersCheckIsPostiesArticle
        ...helpersGetArticleTypeArticle
      }
    `,
    items.at(-1) ?? null,
  );

  const mainArticleType = notEmpty(lastItem) && getArticleType(lastItem);
  const disabledArticleTypes = new Set<string>([
    articleData.types.live.entityId,
    articleData.types.studio.entityId,
  ]);

  const isPosties = notEmpty(lastItem) && checkIsPostiesArticle(lastItem);

  const shouldStopPagination =
    (mainArticleType && disabledArticleTypes.has(mainArticleType)) || isPosties;

  return { shouldStopPagination };
};

type NonEmptyArray<T> = [T, ...T[]];
