import type { FunctionComponent } from "react";
import { JsonLd } from "react-schemaorg";
import type { NewsArticle, ReportageNewsArticle } from "schema-dts";

import { useArticleSchemaContext } from "scmp-app/components/article/article-schema/contexts";
import type { SwGCompatibleSchema } from "scmp-app/components/article/article-schema/types";

type Props = Record<string, never>;

export const ReportageNewsArticleSchema: FunctionComponent<Props> = () => {
  const { schemaData } = useArticleSchemaContext();

  return schemaData ? (
    <JsonLd<SwGCompatibleSchema<NewsArticle, ReportageNewsArticle>>
      item={{
        "@context": "https://schema.org",
        "@type": ["NewsArticle", "ReportageNewsArticle"],
        ...schemaData,
      }}
    />
  ) : null;
};

ReportageNewsArticleSchema.displayName = "ReportageNewsArticleSchema";
